import { NgModule } from '@angular/core';
import { DownloadNotifierComponent } from './components/download-notifier/download-notifier.component';
import { SharedModule } from '../../shared/shared.module';
import { DownloadContentStoreModule } from './store/store.module';
import { DownloadContentModalComponent } from './components/download-content-modal/download-content-modal.component';

@NgModule({
  entryComponents: [
    DownloadContentModalComponent,
  ],
  declarations: [
    DownloadNotifierComponent,
    DownloadContentModalComponent,
  ],
  imports: [
    DownloadContentStoreModule,
    SharedModule,
  ],
  exports: [
    DownloadNotifierComponent,
  ],
})
export class DownloadContentModule {}
