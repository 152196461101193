import { OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class NetworkService {
    constructor() {
        this.onlineSubject = new BehaviorSubject(Boolean(navigator.onLine));
        this.wentOnlineSubject = new Subject();
        this.handleOnline = this.handleOnline.bind(this);
        this.handleOffline = this.handleOffline.bind(this);
        this.addListeners();
    }
    get online() {
        return this.onlineSubject.value;
    }
    get online$() {
        return this.onlineSubject.asObservable();
    }
    get wentOnline$() {
        return this.wentOnlineSubject.asObservable();
    }
    ngOnDestroy() {
        this.wentOnlineSubject.complete();
        this.onlineSubject.complete();
        this.removeListeners();
    }
    addListeners() {
        window.addEventListener('online', this.handleOnline);
        window.addEventListener('offline', this.handleOffline);
    }
    removeListeners() {
        window.removeEventListener('online', this.handleOnline);
        window.removeEventListener('offline', this.handleOffline);
    }
    handleOnline() {
        this.wentOnlineSubject.next();
        this.onlineSubject.next(true);
    }
    handleOffline() {
        this.onlineSubject.next(false);
    }
}
NetworkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NetworkService_Factory() { return new NetworkService(); }, token: NetworkService, providedIn: "root" });
