import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PromptInstallModalComponent } from '../components/prompt-install-modal/prompt-install-modal.component';
import { PromptHelpModalComponent } from '../components/prompt-help-modal/prompt-help-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal";
var UserChoiceOutcome;
(function (UserChoiceOutcome) {
    UserChoiceOutcome["Accepted"] = "accepted";
    UserChoiceOutcome["Dismissed"] = "dismissed";
})(UserChoiceOutcome || (UserChoiceOutcome = {}));
export class PromptInstallService {
    constructor(bsModalService) {
        this.bsModalService = bsModalService;
        this.canPromptInstall = ('onbeforeinstallprompt' in window);
        this.promptInstall = new BehaviorSubject(!this.canPromptInstall);
    }
    get promptInstall$() {
        return this.promptInstall.asObservable();
    }
    ngOnDestroy() {
        this.event = null;
        this.promptInstall.complete();
        this.removeListeners();
    }
    bootstrap() {
        this.handleBeforeInstallPrompt = this.handleBeforeInstallPrompt.bind(this);
        this.handleAppInstalled = this.handleAppInstalled.bind(this);
        this.addListeners();
    }
    prompt() {
        if (!this.event) {
            return;
        }
        this.event.prompt();
        this.event.userChoice
            .then(() => {
            this.event = null;
            this.promptInstall.next(false);
        });
    }
    showModal() {
        const modal = this.bsModalService.show(PromptInstallModalComponent);
        return modal.content.confirm;
    }
    install() {
        if (this.canPromptInstall) {
            return this.prompt();
        }
        this.bsModalService.show(PromptHelpModalComponent);
    }
    addListeners() {
        window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', this.handleAppInstalled);
    }
    removeListeners() {
        window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
        window.removeEventListener('appinstalled', this.handleAppInstalled);
    }
    handleBeforeInstallPrompt(event) {
        this.event = event;
        this.promptInstall.next(true);
    }
    handleAppInstalled() {
        this.event = null;
        this.promptInstall.next(false);
    }
}
PromptInstallService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromptInstallService_Factory() { return new PromptInstallService(i0.ɵɵinject(i1.BsModalService)); }, token: PromptInstallService, providedIn: "root" });
