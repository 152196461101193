import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as downloadContentActions from '../actions/download-content.actions';
import { of, EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap, filter, concatMap } from 'rxjs/operators';
import * as mapTilesActions from '../../../../store/actions/map-tiles.actions';
import * as mapPreloadActions from '../../../../store/actions/map-preload.actions';
import * as entriesMediaActions from '../../../../store/actions/entries-media.actions';
import * as entriesMediaPreloadActions from '../../../../store/actions/entries-media-preload.actions';
import { DownloadContentService } from '../../services/download-content.service';

@Injectable()
export class DownloadContentEffects {
  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetch),
      switchMap(() => {
        return this.downloadContentService.fetch()
          .pipe(
            map((response) => downloadContentActions.fetchSuccess({
              response,
            })),
            catchError(() => of(downloadContentActions.fetchFailed())),
          );
      }),
    );
  });

  public fetchSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetchSuccess),
      tap(() => {
        this.downloadContentService.showSuccess();
      }),
    );
  }, {
    dispatch: false,
  });

  public updateMapTiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetchSuccess),
      map((action) => action.response.map),
      filter((response) => Boolean(response.data)),
      map((response) => mapTilesActions.updateStorage(response)),
    );
  });

  public updateEntriesMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetchSuccess),
      map((action) => action.response.entries),
      filter((response) => Boolean(response.data)),
      map((response) => entriesMediaActions.updateStorage(response)),
    );
  });

  public fetchFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetchFailed),
      tap(() => {
        this.downloadContentService.showFail();
      }),
    );
  }, {
    dispatch: false,
  });

  public remove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.remove),
      switchMap(() => {
        return this.downloadContentService.remove()
          .pipe(
            map((response) => downloadContentActions.removeSuccess({
              response,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public removeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.removeSuccess),
      map((action) => action.response),
      concatMap((response) => [
        entriesMediaPreloadActions.fetchSuccess({
          response: response.entries,
        }),
        mapPreloadActions.fetchSuccess({
          response: response.map,
        }),
      ]),
    );
  });

  constructor(
    private actions$: Actions,
    private downloadContentService: DownloadContentService,
  ) {}
}
