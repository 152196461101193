import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { PromptInstallEffects } from './prompt-install.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      PromptInstallEffects,
    ]),
  ],
})
export class PromptInstallEffectsModule {}
