import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export enum SafeType {
  Html = 'html',
  ResourceUrl = 'resourceUrl',
}

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: string, type: SafeType) {
    switch (type) {
      case SafeType.Html: {
        return this.sanitizer.bypassSecurityTrustHtml(value);
      }

      case SafeType.ResourceUrl: {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      }
    }
  }
}
