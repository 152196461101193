/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./categories-widget-item.component";
var styles_CategoriesWidgetItemComponent = [];
var RenderType_CategoriesWidgetItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CategoriesWidgetItemComponent, data: {} });
export { RenderType_CategoriesWidgetItemComponent as RenderType_CategoriesWidgetItemComponent };
export function View_CategoriesWidgetItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "img-fluid"], ["width", "60px"]], [[1, "src", 4], [1, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "p", [["class", "text-sm mb-0 mt-1"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; var currVal_1 = _co.category.name; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.category.name; _ck(_v, 1, 0, currVal_2); }); }
export function View_CategoriesWidgetItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-categories-widget-item", [], null, null, null, View_CategoriesWidgetItemComponent_0, RenderType_CategoriesWidgetItemComponent)), i0.ɵdid(1, 49152, null, 0, i1.CategoriesWidgetItemComponent, [], null, null)], null, null); }
var CategoriesWidgetItemComponentNgFactory = i0.ɵccf("odn-categories-widget-item", i1.CategoriesWidgetItemComponent, View_CategoriesWidgetItemComponent_Host_0, { category: "category", image: "image" }, {}, []);
export { CategoriesWidgetItemComponentNgFactory as CategoriesWidgetItemComponentNgFactory };
