import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, AppRouterState } from '..';

export const selectRouter = createFeatureSelector<AppState, AppRouterState>('router');

export const getRouterState = createSelector(
  selectRouter,
  (router) => router && router.state,
);

export const getRouterQueryParams = createSelector(
  getRouterState,
  (routerState) => routerState && routerState.queryParams,
);

export const getQueryParamTerm = createSelector(
  getRouterQueryParams,
  (queryParams) => queryParams && queryParams.term,
);

export const getRouterUrl = createSelector(
  getRouterState,
  (routerState) => routerState && routerState.url,
);

export const getRouterDocLink = createSelector(
  getRouterUrl,
  (url) => url && url.slice(1),
);
