import { NgModule } from '@angular/core';
import {
  faHome, faHeart, faBookmark, faSearch, faShareAlt, faInfoCircle, faPhoneVolume,
  faMobile, faMap as fasMap, faTimes, faEllipsisH, faUserTie, faGlobe, faAt, faFax, faMapMarkerAlt, faAngleDown, faAngleUp,
  faClock, faDownload, faSync, faCog, faSortAmountDownAlt, faCompass,
} from '@fortawesome/free-solid-svg-icons';
import { faMap as farMap } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';

@NgModule()
export class IconModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(
      faHome, faHeart, faBookmark, faSearch, faShareAlt, faInfoCircle, faPhoneVolume, faMobile, faSortAmountDownAlt,
      fasMap, faTimes, faEllipsisH, faUserTie, faGlobe, faAt, faFax, faMapMarkerAlt, faAngleDown, faAngleUp,
      faClock, faDownload, faFacebookF, faFacebookMessenger, faTwitter, faSync, faCog, faInstagram, farMap, faCompass,
    );
  }
}
