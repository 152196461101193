import { TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class NotificationService {
    constructor() {
        this.notifications = [];
        this.subject = new BehaviorSubject([]);
    }
    get notifications$() {
        return this.subject.asObservable();
    }
    show(content, options) {
        this.notifications = [
            ...this.notifications,
            Object.assign({ content }, options),
        ];
        this.emit();
    }
    success(content, options) {
        this.show(content, Object.assign({}, options, { classname: 'bg-success text-light' }));
    }
    danger(content, options) {
        this.show(content, Object.assign({}, options, { classname: 'bg-danger text-light' }));
    }
    remove(notification) {
        this.notifications = this.notifications.filter((item) => item !== notification);
        this.emit();
    }
    emit() {
        this.subject.next(this.notifications);
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
