import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService implements OnDestroy {
  private onlineSubject = new BehaviorSubject(Boolean(navigator.onLine));
  private wentOnlineSubject = new Subject<void>();

  constructor() {
    this.handleOnline = this.handleOnline.bind(this);
    this.handleOffline = this.handleOffline.bind(this);

    this.addListeners();
  }

  public get online() {
    return this.onlineSubject.value;
  }

  public get online$() {
    return this.onlineSubject.asObservable();
  }

  public get wentOnline$() {
    return this.wentOnlineSubject.asObservable();
  }

  public ngOnDestroy() {
    this.wentOnlineSubject.complete();
    this.onlineSubject.complete();

    this.removeListeners();
  }

  private addListeners() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  private removeListeners() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  private handleOnline() {
    this.wentOnlineSubject.next();
    this.onlineSubject.next(true);
  }

  private handleOffline() {
    this.onlineSubject.next(false);
  }
}
