import { Injectable, Inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PromptUpdateService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private swUpdate: SwUpdate,
  ) {}

  public bootstrap() {
    this.swUpdate.available.subscribe(() => {
      alert('Νέα έκδοση είναι διαθέσιμη, πατήστε "Κλείσιμο" για εγκατάσταση.');

      this.swUpdate.activateUpdate().then(() => this.document.location.reload());
    });
  }
}
