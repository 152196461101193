import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { filter, map, switchMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as entriesMediaActions from '../actions/entries-media.actions';
import * as versionsActions from '../actions/versions.actions';
import { EntriesMediaService } from '../../services/entries-media.service';
import { select, Store } from '@ngrx/store';
import { getInstallationId } from '../selectors/installation.selectors';
import { VersionsService } from '../../services/versions.service';
import { AppState } from '../index';
import { Table } from '../../services/data.service';

@Injectable()
export class EntriesMediaEffects {
  public init$ = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    concatMap((action) => {
      return of(action)
        .pipe(
          withLatestFrom(
            this.store.pipe(select(getInstallationId)),
            (a, installationId) => installationId,
          ),
        );
    }),
    switchMap((installationId) => {
      return this.versionsService.get(Table.EntriesMedia)
        .pipe(
          filter((version) => Boolean(installationId && version)),
          map(() => entriesMediaActions.fetch()),
          catchError(() => EMPTY),
        );
    }),
  ));

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesMediaActions.fetch),
      switchMap(() => {
        return this.entriesMediaService.fetch()
          .pipe(
            filter((response) => Boolean(response.data)),
            map((response) => entriesMediaActions.updateStorage(response)),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public handleAdditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesMediaActions.updateStorage),
      switchMap((action) => {
        return this.entriesMediaService.handleAdditions(action.data.additions, action.data.partial)
          .pipe(
            filter(Boolean),
            map(() => versionsActions.update({
              id: Table.EntriesMedia,
              value: action.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public handleDeletions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesMediaActions.updateStorage),
      switchMap((action) => {
        return this.entriesMediaService.handleDeletions(action.data.deletions)
          .pipe(
            filter(Boolean),
            map(() => versionsActions.update({
              id: Table.EntriesMedia,
              value: action.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private entriesMediaService: EntriesMediaService,
    private versionsService: VersionsService,
  ) {}
}
