/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./pipes/link-page-replace.pipe";
import * as i4 from "ngx-bootstrap/dropdown";
import * as i5 from "ngx-bootstrap/component-loader";
import * as i6 from "./navbar.component";
import * as i7 from "@ngrx/store";
var styles_NavbarComponent = [];
var RenderType_NavbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "nav-item flex-fill"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "nav-link p-0"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["height", "40px"], ["width", "40px"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.commands; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; var currVal_1 = i0.ɵnov(_v, 2).target; var currVal_2 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = (("assets/" + _v.context.$implicit.icon) + ".svg"); var currVal_5 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_4, currVal_5); }); }
function View_NavbarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "nav-link"], ["target", "_blank"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(2, 2), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_2 = i0.ɵnov(_v.parent, 4); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.link, i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.routerDocLink$)))); var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NavbarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "nav-link"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.commands; _ck(_v, 1, 0, currVal_3); var currVal_4 = i0.ɵnov(_v.parent, 4); _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NavbarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["height", "40px"], ["width", "40px"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (("assets/" + _v.parent.context.$implicit.icon) + ".svg"); var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_2); }); }
function View_NavbarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavbarComponent_4)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["internalLink", 2]], null, 0, null, View_NavbarComponent_5)), (_l()(), i0.ɵand(0, [["linkContent", 2]], null, 0, null, View_NavbarComponent_6))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.link; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NavbarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["aria-labelledby", "button-dropup"], ["class", "dropdown-menu dropdown-menu-right"], ["id", "dropdown-dropup"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavbarComponent_3)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subNavItems; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NavbarComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.LinkPageReplacePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 11, "nav", [["class", "navbar navbar-expand navbar-light fixed-bottom bg-white p-0 border-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "ul", [["class", "navbar-nav flex-fill text-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i0.ɵdid(4, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 16777216, null, null, 7, "li", [["class", "nav-item flex-fill btn-group"], ["dropdown", ""], ["placement", "top right"]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i0.ɵprd(512, null, i4.BsDropdownState, i4.BsDropdownState, []), i0.ɵdid(7, 212992, null, 0, i4.BsDropdownDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i5.ComponentLoaderFactory, i4.BsDropdownConfig, i4.BsDropdownState], { placement: [0, "placement"], dropup: [1, "dropup"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-link nav-link p-0 border-0"], ["dropdownToggle", ""], ["id", "button-dropup"], ["title", "\u03A0\u03B5\u03C1\u03B9\u03C3\u03C3\u03CC\u03C4\u03B5\u03C1\u03B1"], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 147456, null, 0, i4.BsDropdownToggleDirective, [i0.ChangeDetectorRef, i4.BsDropdownDirective, i0.ElementRef, i0.Renderer2, i4.BsDropdownState], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["alt", "\u03A0\u03B5\u03C1\u03B9\u03C3\u03C3\u03CC\u03C4\u03B5\u03C1\u03B1"], ["height", "40px"], ["src", "assets/burger.svg"], ["width", "40px"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i0.ɵdid(12, 16384, null, 0, i4.BsDropdownMenuDirective, [i4.BsDropdownState, i0.ViewContainerRef, i0.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItems; _ck(_v, 4, 0, currVal_0); var currVal_4 = "top right"; var currVal_5 = true; _ck(_v, 7, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 7).dropup; var currVal_2 = i0.ɵnov(_v, 7).isOpen; var currVal_3 = (i0.ɵnov(_v, 7).isOpen && i0.ɵnov(_v, 7).isBs4); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = true; var currVal_7 = i0.ɵnov(_v, 9).isDisabled; var currVal_8 = i0.ɵnov(_v, 9).isOpen; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_NavbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i0.ɵdid(1, 114688, null, 0, i6.NavbarComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i0.ɵccf("odn-navbar", i6.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
