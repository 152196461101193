<ngb-toast
  *ngFor="let notification of notificationService.notifications$ | async"
  [class]="notification.classname"
  [delay]="notification.delay || delay"
  (hide)="notificationService.remove(notification)">
  <ng-container *ngIf="isTemplate(notification); else text">
    <ng-template [ngTemplateOutlet]="notification.content"></ng-template>
  </ng-container>
  <ng-template #text>{{ notification.content }}</ng-template>
</ngb-toast>
