import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

type Schema = {
  [key in Table]: string;
};

export enum Table {
  Categories = 'categories',
  Versions = 'versions',
  Entries = 'entries',
  EntriesMedia = 'entriesMedia',
  CategoriesMedia = 'categoriesMedia',
  MapTiles = 'mapTiles',
  Favorites = 'favorites',
  Info = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class DataService extends Dexie {
  private static readonly schema: Schema = {
    categories: 'id,name,parent_id,order',
    entries: 'id',
    entriesMedia: 'id',
    categoriesMedia: 'id',
    mapTiles: 'id',
    versions: 'id',
    favorites: 'id',
    info: 'id,page',
  };

  constructor() {
    super('odigosnotia');

    this.createStores();
  }

  public reset() {
    return from(this.delete())
      .pipe(
        switchMap(() => {
          return from(this.open());
        }),
      );
  }

  private createStores() {
    this.version(1).stores(DataService.schema);
  }
}
