import { createAction } from '@ngrx/store';

export enum Actions {
  Init = '[Prompt Install] Init',
  ShowModal = '[Prompt Install] Show Modal',
  Install = '[Prompt Install] Install',
}

export const init = createAction(Actions.Init);

export const showModal = createAction(Actions.ShowModal);

export const install = createAction(Actions.Install);
