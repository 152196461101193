import { DataService, Table } from './data.service';
import { ApiService } from './api.service';
import { MediaService } from './media.service';
import { Injectable, NgZone } from '@angular/core';
import { VersionsService } from './versions.service';
import { ListingServiceBase } from './listing.service';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapTilesService extends MediaService {
  constructor(
    apiService: ApiService,
    dataService: DataService,
    versionsService: VersionsService,
    ngZone: NgZone,
  ) {
    super(
      apiService,
      dataService,
      versionsService,
      ngZone,
      Table.MapTiles,
      ListingServiceBase.MapTiles,
    );
  }

  public clearPartial() {
    return from(this.table.where('id').startsWithAnyOf(['15-', '16-', '17-']).delete());
  }
}
