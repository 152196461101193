import { NgZone } from '@angular/core';
import { ApiService } from './api.service';
import { merge, from, EMPTY } from 'rxjs';
import { DataService, Table } from './data.service';
import { switchMap } from 'rxjs/operators';
import { VersionsService } from './versions.service';
import { ListingService, ListingServiceBase } from './listing.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./data.service";
import * as i3 from "./versions.service";
export class CategoriesService extends ListingService {
    constructor(apiService, dataService, versionsService, ngZone) {
        super(apiService, dataService, versionsService, ngZone, Table.Categories, ListingServiceBase.Categories);
    }
    fetchCategoriesByParentId(parentId = '0') {
        return merge(this.getCategoriesByParentId(parentId), this.updates$.pipe(switchMap(() => this.getCategoriesByParentId(parentId))));
    }
    fetchParent(id) {
        return merge(this.getParent(id), this.updates$.pipe(switchMap(() => this.getParent(id))));
    }
    getCategoriesByParentId(parentId = '0') {
        return from(this.table.where('parent_id').equals(parentId).sortBy('order'));
    }
    getParent(id) {
        return this.getOne(id)
            .pipe(switchMap((category) => {
            if (!category) {
                return EMPTY;
            }
            return this.getOne(category.parent_id);
        }));
    }
}
CategoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesService_Factory() { return new CategoriesService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.DataService), i0.ɵɵinject(i3.VersionsService), i0.ɵɵinject(i0.NgZone)); }, token: CategoriesService, providedIn: "root" });
