import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AppNotification {
  content: string | TemplateRef<any>;
  delay: number;
  classname: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notifications: AppNotification[] = [];
  private subject = new BehaviorSubject<AppNotification[]>([]);

  public get notifications$() {
    return this.subject.asObservable();
  }

  public show(content: string | TemplateRef<any>, options?: AppNotification) {
    this.notifications = [
      ...this.notifications,
      {
        content,
        ...options,
      },
    ];

    this.emit();
  }

  public success(content: string | TemplateRef<any>, options?: AppNotification) {
    this.show(content, {
      ...options,
      classname: 'bg-success text-light',
    });
  }

  public danger(content: string | TemplateRef<any>, options?: AppNotification) {
    this.show(content, {
      ...options,
      classname: 'bg-danger text-light',
    });
  }

  public remove(notification: AppNotification) {
    this.notifications = this.notifications.filter((item) => item !== notification);

    this.emit();
  }

  private emit() {
    this.subject.next(this.notifications);
  }
}
