import { NotificationService, AppNotification } from '../../services/notification.service';
import { Component, TemplateRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-notifications',
  templateUrl: 'notifications.component.html',
  styleUrls: [
    'notifications.component.scss',
  ],
})
export class NotificationsComponent {
  public delay = 5000;

  constructor(public notificationService: NotificationService) {}

  public isTemplate(notification: AppNotification) {
    return notification.content instanceof TemplateRef;
  }
}
