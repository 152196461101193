import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Category } from 'src/app/models/category';
import { CategoriesService } from '../../services/categories.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CategoriesImagesService, CategoriesImages } from '../../services/categories-images.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-home',
  templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit {
  public categories$: Observable<Category[]>;
  public categoriesImages$: Observable<CategoriesImages>;

  constructor(
    private categoriesService: CategoriesService,
    private router: Router,
    private categoriesImagesService: CategoriesImagesService,
  ) {}

  public ngOnInit() {
    this.categories$ = this.categoriesService.fetchCategoriesByParentId();
    this.categoriesImages$ = this.categories$.pipe(
      switchMap((categories) => {
        return this.categoriesImagesService.fetch(categories);
      }),
    );
  }

  public onSelectCategory(category: Category) {
    this.router.navigate(['/categories', category.id, 'list']);
  }
}
