<odn-header></odn-header>
<div class="container-fluid">
  <main class="py-2">
    <router-outlet></router-outlet>
  </main>
</div>
<ng-container *ngIf="matchMediaService.isStandalone; else promptInstallNotifier">
  <odn-download-notifier
    *ngIf="canDownload$ | async"
    [loading]="downloadContentLoading$ | async"
    [online]="online$ | async"
    (confirm)="onConfirmDownloadNotifier()">
  </odn-download-notifier>
</ng-container>
<ng-template #promptInstallNotifier>
  <odn-prompt-install-notifier
    *ngIf="promptInstall$ | async"
    (confirm)="onConfirmPromptInstall()">
  </odn-prompt-install-notifier>
</ng-template>
<odn-footer></odn-footer>
<odn-notifications aria-live="polite" aria-atomic="true"></odn-notifications>
