import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as categoriesActions from '../actions/categories.actions';
import * as versionsActions from '../actions/versions.actions';
import { map, filter, switchMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { CategoriesService } from '../../services/categories.service';
import { EMPTY, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '..';
import { getInstallationId } from '../selectors/installation.selectors';
import { Table } from '../../services/data.service';

@Injectable()
export class CategoriesEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => of(action)
        .pipe(
          withLatestFrom(
            this.store.pipe(select(getInstallationId)),
            (a, installationId) => installationId,
          ),
        ),
      ),
      filter<string>(Boolean),
      map(() => categoriesActions.fetch()),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoriesActions.fetch),
      switchMap(() => {
        return this.categoriesService.fetch()
          .pipe(
            filter((response) => Boolean(response.data)),
            map((response) => categoriesActions.updateStorage(response)),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public updateStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoriesActions.updateStorage),
      switchMap((action) => {
        return this.categoriesService.updateStorage(Object.values(action.data))
          .pipe(
            map(() => versionsActions.update({
              id: Table.Categories,
              value: action.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private categoriesService: CategoriesService,
    private store: Store<AppState>,
  ) {}
}
