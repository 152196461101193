import { Injectable } from '@angular/core';
import { EntriesService } from '../../../services/entries.service';
import { map, switchMap } from 'rxjs/operators';
import { EntriesMediaService } from '../../../services/entries-media.service';
import { Entry } from '../../../models/entry';

function selectEntries(entries: Entry[]) {
  if (entries.length < 4) {
    return entries.slice().sort(() => Math.random() - 0.5);
  }

  const result: Entry[] = [];

  do {
    const index = Math.floor(Math.random() * entries.length);
    const entry = entries[index];

    if (!result.includes(entry)) {
      result.push(entry);
    }
  } while (result.length < 3);

  return result;
}

export interface Banner {
  name: string;
  image: string;
  id?: string;
  link?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(
    private entriesService: EntriesService,
    private entriesMediaService: EntriesMediaService,
  ) {}

  public fromCategories(categoryIds: string[]) {
    return this.entriesService.getFeaturedEntries(categoryIds)
      .pipe(
        switchMap((entries) => this.fromEntries(entries)),
      );
  }

  public fromEntries(entries: Entry[]) {
    const selected = selectEntries(entries);
    const ids = selected.map((entry) => entry.gallery[0]);

    return this.entriesMediaService.getMany(ids)
      .pipe(
        map((mediaItems) => {
          if (!mediaItems.length) {
            return [];
          }

          return selected.map((entry) => {
            const mediaItem = mediaItems.find((item) => entry.gallery[0] === item.id);

            return {
              name: entry.name,
              id: entry.id,
              image: mediaItem.url,
            };
          });
        }),
      );
  }
}
