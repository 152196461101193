import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

export interface ApiDataResponse<T> {
  version: string;
  data: {
    [id: string]: T;
  };
}

export interface GetRequestOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
}

export const VERSION_HEADER = 'Odn-Collection-Version';
export const INSTALLATION_HEADER = 'Odn-Installation-Guid';
export const CLIENT_VERSION_HEADER = 'Odn-Client-Version';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private static readonly base = environment.api.base;

  constructor(private http: HttpClient) {}

  public get<T>(url: string, options?: GetRequestOptions) {
    return this.http.get<T>(`${ApiService.base}/${url}`, options)
      .pipe(catchError(this.onError));
  }

  public post<T>(url: string, payload: any) {
    return this.http.post<T>(`${ApiService.base}/${url}`, payload)
      .pipe(catchError(this.onError));
  }

  private onError(response: HttpErrorResponse) {
    return throwError(response);
  }
}
