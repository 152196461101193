import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Installation } from '../reducers/installation.reducer';
import { AppState } from '..';

export const selectInstallation = createFeatureSelector<AppState, Installation>('installation');

export const getInstallationId = createSelector(
  selectInstallation,
  (installation) => installation.id,
);
