import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MapTilesService } from '../../../services/map-tiles.service';
import { EntriesMediaService } from '../../../services/entries-media.service';
import { NotificationService } from '../../../services/notification.service';
import { switchMap, map, mapTo } from 'rxjs/operators';
import { Table } from '../../../services/data.service';
import { VersionsService } from '../../../services/versions.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DownloadContentModalComponent } from '../components/download-content-modal/download-content-modal.component';
import { MapPreloadService } from '../../../services/map-preload.service';
import { EntriesMediaPreloadService } from '../../../services/entries-media-preload.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadContentService {
  private readonly tables = [
    Table.EntriesMedia,
    Table.MapTiles,
  ];

  constructor(
    private mapTilesService: MapTilesService,
    private mapPreloadService: MapPreloadService,
    private entriesMediaService: EntriesMediaService,
    private entriesMediaPreloadService: EntriesMediaPreloadService,
    private notificationService: NotificationService,
    private versionsService: VersionsService,
    private bsModalService: BsModalService,
  ) {}

  public get canDownload$() {
    return this.versionsService.fetchMany(this.tables)
      .pipe(
        map((versions) => {
          return versions.length !== this.tables.length;
        }),
    );
  }

  public fetch() {
    return forkJoin({
      map: this.mapTilesService.fetch(),
      entries: this.entriesMediaService.fetchClean(),
    });
  }

  public remove() {
    return this.fetchPreload()
      .pipe(
        switchMap((response) => {
          return this.removeAndClear()
            .pipe(
              mapTo(response),
            );
        }),
      );
  }

  public showModal() {
    const modal = this.bsModalService.show(DownloadContentModalComponent);

    return (modal.content as DownloadContentModalComponent).confirm;
  }

  public showSuccess() {
    this.notificationService.success('Επιτυχής λήψη του επιπρόσθετου περιεχομένου!');
  }

  public showFail() {
    this.notificationService.danger('Παρουσιάστηκε κάποιο πρόβλημα με την λήψη του επιπρόσθετου περιεχομένου. Παρακαλούμε προσπαθήστε αργότερα.');
  }

  private fetchPreload() {
    return forkJoin({
      map: this.mapPreloadService.fetch(),
      entries: this.entriesMediaPreloadService.fetch(),
    });
  }

  private removeAndClear() {
    return this.versionsService.removeMany([
      Table.MapTiles,
      Table.EntriesMedia,
    ]).pipe(
      switchMap(() => {
        return forkJoin([
          this.mapTilesService.clearPartial(),
          this.entriesMediaService.clear(),
        ]);
      }),
    );
  }
}
