import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as infoActions from '../actions/info.actions';
import * as versionsActions from '../actions/versions.actions';
import { map, filter, switchMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { InfoService } from '../../services/info.service';
import { EMPTY, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '..';
import { getInstallationId } from '../selectors/installation.selectors';
import { Table } from '../../services/data.service';

@Injectable()
export class InfoEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => of(action)
        .pipe(
          withLatestFrom(
            this.store.pipe(select(getInstallationId)),
            (a, installationId) => installationId,
          ),
        ),
      ),
      filter<string>(Boolean),
      map(() => infoActions.fetch()),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(infoActions.fetch),
      switchMap(() => {
        return this.infoService.fetch()
          .pipe(
            filter((response) => Boolean(response.data)),
            map((response) => infoActions.updateStorage({
              payload: response,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public updateStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(infoActions.updateStorage),
      switchMap((action) => {
        return this.infoService.updateStorage(action.payload.data)
          .pipe(
            map(() => versionsActions.update({
              id: Table.Info,
              value: action.payload.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private infoService: InfoService,
    private store: Store<AppState>,
  ) {}
}
