import { Pipe, PipeTransform } from '@angular/core';
import { PAGE_REPLACE_PATTERN } from '../navbar.component';

@Pipe({
  name: 'linkPageReplace',
})
export class LinkPageReplacePipe implements PipeTransform {
  public transform(link: string, docLink: string) {
    return link.replace(PAGE_REPLACE_PATTERN, docLink || '');
  }
}
