/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./components/categories-widget-item/categories-widget-item.component.ngfactory";
import * as i3 from "./components/categories-widget-item/categories-widget-item.component";
import * as i4 from "./categories-widget.component";
var styles_CategoriesWidgetComponent = [];
var RenderType_CategoriesWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CategoriesWidgetComponent, data: {} });
export { RenderType_CategoriesWidgetComponent as RenderType_CategoriesWidgetComponent };
function View_CategoriesWidgetComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "col-4 mt-2 text-center"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
function View_CategoriesWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CategoriesWidgetComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-4 mt-2 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "odn-categories-widget-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectCategory.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CategoriesWidgetItemComponent_0, i2.RenderType_CategoriesWidgetItemComponent)), i0.ɵdid(5, 49152, null, 0, i3.CategoriesWidgetItemComponent, [], { category: [0, "category"], image: [1, "image"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.index && (_v.context.index === _co.index)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = ((_co.categoriesImages && _co.categoriesImages[_v.context.$implicit.image]) || _co.fallbackCategoryImage); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_CategoriesWidgetComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row mt-n2 mx-n2 no-gutters odn-overflow-hidden"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CategoriesWidgetComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; var currVal_1 = _co.trackByCategoryId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CategoriesWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-categories-widget", [], null, null, null, View_CategoriesWidgetComponent_0, RenderType_CategoriesWidgetComponent)), i0.ɵdid(1, 49152, null, 0, i4.CategoriesWidgetComponent, [], null, null)], null, null); }
var CategoriesWidgetComponentNgFactory = i0.ɵccf("odn-categories-widget", i4.CategoriesWidgetComponent, View_CategoriesWidgetComponent_Host_0, { categories: "categories", categoriesImages: "categoriesImages", index: "index" }, { selectCategory: "selectCategory" }, ["*"]);
export { CategoriesWidgetComponentNgFactory as CategoriesWidgetComponentNgFactory };
