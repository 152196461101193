import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { catchError, filter, switchMap, map, concatMap, withLatestFrom } from 'rxjs/operators';
import * as mapTilesActions from '../actions/map-tiles.actions';
import * as versionActions from '../actions/versions.actions';
import { MapTilesService } from '../../services/map-tiles.service';
import { EMPTY, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getInstallationId } from '../selectors/installation.selectors';
import { AppState } from '../index';
import { VersionsService } from '../../services/versions.service';
import { Table } from '../../services/data.service';

@Injectable()
export class MapTilesEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      switchMap((installationId) => {
        return this.versionsService.get(Table.MapTiles)
          .pipe(
            filter((version) => Boolean(installationId && version)),
            map(() => mapTilesActions.fetch()),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mapTilesActions.fetch),
      switchMap(() => {
        return this.mapTilesService.fetch()
          .pipe(
            filter((response) => Boolean(response.data)),
            map((response) => mapTilesActions.updateStorage(response)),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public handleAdditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mapTilesActions.updateStorage),
      switchMap((action) => {
        return this.mapTilesService.handleAdditions(action.data.additions, action.data.partial)
          .pipe(
            filter(Boolean),
            map(() => versionActions.update({
              id: Table.MapTiles,
              value: action.version,
            })),
          );
      }),
    );
  });

  public handleDeletions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mapTilesActions.updateStorage),
      switchMap((action) => {
        return this.mapTilesService.handleDeletions(action.data.deletions)
          .pipe(
            filter(Boolean),
            map(() => versionActions.update({
              id: Table.MapTiles,
              value: action.version,
            })),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private mapTilesService: MapTilesService,
    private versionsService: VersionsService,
  ) {}
}
