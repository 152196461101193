<odn-categories-widget
  [categories]="categories$ | async"
  [categoriesImages]="categoriesImages$ | async"
  [index]="4"
  (selectCategory)="onSelectCategory($event)">
  <a
    routerLink="/search"
    class="btn btn-lg border-primary bg-info odn-btn-circle d-flex justify-content-center align-items-center mx-auto">
    <img src="assets/search.svg" width="40px" height="40px" alt="Αναζήτηση" />
  </a>
  <p class="text-sm mb-0 mt-1">Αναζήτηση</p>
</odn-categories-widget>
<odn-banner></odn-banner>
