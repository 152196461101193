import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ShareService, SocialProvider, AVAILABLE_SOCIAL_PROVIDERS } from '../../services/share.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-share-modal',
  templateUrl: 'share-modal.component.html',
})
export class ShareModalComponent {
  public availableSocialProviders = AVAILABLE_SOCIAL_PROVIDERS;

  @Input()
  public url: string;

  constructor(
    public bsModalRef: BsModalRef,
    private shareService: ShareService,
  ) {}

  public share(socialProvider: SocialProvider) {
    this.shareService.share(socialProvider, this.url);
    this.bsModalRef.hide();
  }
}
