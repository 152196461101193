import { forkJoin } from 'rxjs';
import { MapTilesService } from '../../../services/map-tiles.service';
import { EntriesMediaService } from '../../../services/entries-media.service';
import { NotificationService } from '../../../services/notification.service';
import { switchMap, map, mapTo } from 'rxjs/operators';
import { Table } from '../../../services/data.service';
import { VersionsService } from '../../../services/versions.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DownloadContentModalComponent } from '../components/download-content-modal/download-content-modal.component';
import { MapPreloadService } from '../../../services/map-preload.service';
import { EntriesMediaPreloadService } from '../../../services/entries-media-preload.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/map-tiles.service";
import * as i2 from "../../../services/map-preload.service";
import * as i3 from "../../../services/entries-media.service";
import * as i4 from "../../../services/entries-media-preload.service";
import * as i5 from "../../../services/notification.service";
import * as i6 from "../../../services/versions.service";
import * as i7 from "ngx-bootstrap/modal";
export class DownloadContentService {
    constructor(mapTilesService, mapPreloadService, entriesMediaService, entriesMediaPreloadService, notificationService, versionsService, bsModalService) {
        this.mapTilesService = mapTilesService;
        this.mapPreloadService = mapPreloadService;
        this.entriesMediaService = entriesMediaService;
        this.entriesMediaPreloadService = entriesMediaPreloadService;
        this.notificationService = notificationService;
        this.versionsService = versionsService;
        this.bsModalService = bsModalService;
        this.tables = [
            Table.EntriesMedia,
            Table.MapTiles,
        ];
    }
    get canDownload$() {
        return this.versionsService.fetchMany(this.tables)
            .pipe(map((versions) => {
            return versions.length !== this.tables.length;
        }));
    }
    fetch() {
        return forkJoin({
            map: this.mapTilesService.fetch(),
            entries: this.entriesMediaService.fetchClean(),
        });
    }
    remove() {
        return this.fetchPreload()
            .pipe(switchMap((response) => {
            return this.removeAndClear()
                .pipe(mapTo(response));
        }));
    }
    showModal() {
        const modal = this.bsModalService.show(DownloadContentModalComponent);
        return modal.content.confirm;
    }
    showSuccess() {
        this.notificationService.success('Επιτυχής λήψη του επιπρόσθετου περιεχομένου!');
    }
    showFail() {
        this.notificationService.danger('Παρουσιάστηκε κάποιο πρόβλημα με την λήψη του επιπρόσθετου περιεχομένου. Παρακαλούμε προσπαθήστε αργότερα.');
    }
    fetchPreload() {
        return forkJoin({
            map: this.mapPreloadService.fetch(),
            entries: this.entriesMediaPreloadService.fetch(),
        });
    }
    removeAndClear() {
        return this.versionsService.removeMany([
            Table.MapTiles,
            Table.EntriesMedia,
        ]).pipe(switchMap(() => {
            return forkJoin([
                this.mapTilesService.clearPartial(),
                this.entriesMediaService.clear(),
            ]);
        }));
    }
}
DownloadContentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DownloadContentService_Factory() { return new DownloadContentService(i0.ɵɵinject(i1.MapTilesService), i0.ɵɵinject(i2.MapPreloadService), i0.ɵɵinject(i3.EntriesMediaService), i0.ɵɵinject(i4.EntriesMediaPreloadService), i0.ɵɵinject(i5.NotificationService), i0.ɵɵinject(i6.VersionsService), i0.ɵɵinject(i7.BsModalService)); }, token: DownloadContentService, providedIn: "root" });
