/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./share-modal.component";
import * as i6 from "ngx-bootstrap/modal";
import * as i7 from "../../services/share.service";
var styles_ShareModalComponent = [];
var RenderType_ShareModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareModalComponent, data: {} });
export { RenderType_ShareModalComponent as RenderType_ShareModalComponent };
function View_ShareModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "btn btn-block text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i0.ɵdid(4, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i0.ɵpad(5, 2), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "pl-2"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "btn btn-block text-white"; var currVal_1 = ("odn-btn-" + _v.context.$implicit.key); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _ck(_v, 5, 0, "fab", _v.context.$implicit.value.icon); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 4).title; var currVal_3 = i0.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.value.title; _ck(_v, 6, 0, currVal_5); }); }
export function View_ShareModalComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u039C\u03BF\u03B9\u03C1\u03AC\u03C3\u03BF\u03C5 \u03C4\u03BF"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-describedby", "modal-title"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["icon", "times"], ["title", "\u039A\u03BB\u03B5\u03AF\u03C3\u03B9\u03BC\u03BF \u03BA\u03BF\u03B9\u03BD\u03AE\u03C2 \u03C7\u03C1\u03AE\u03C3\u03B7\u03C2"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i0.ɵdid(5, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"], title: [1, "title"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ShareModalComponent_1)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i1.KeyValuePipe, [i0.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "times"; var currVal_3 = "\u039A\u03BB\u03B5\u03AF\u03C3\u03B9\u03BC\u03BF \u03BA\u03BF\u03B9\u03BD\u03AE\u03C2 \u03C7\u03C1\u03AE\u03C3\u03B7\u03C2"; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.availableSocialProviders)); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).title; var currVal_1 = i0.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_ShareModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-share-modal", [], null, null, null, View_ShareModalComponent_0, RenderType_ShareModalComponent)), i0.ɵdid(1, 49152, null, 0, i5.ShareModalComponent, [i6.BsModalRef, i7.ShareService], null, null)], null, null); }
var ShareModalComponentNgFactory = i0.ɵccf("odn-share-modal", i5.ShareModalComponent, View_ShareModalComponent_Host_0, { url: "url" }, {}, []);
export { ShareModalComponentNgFactory as ShareModalComponentNgFactory };
