import { Injectable } from '@angular/core';
import { Category } from '../models/category';
import { CategoriesMediaService } from './categories-media.service';
import { map } from 'rxjs/operators';

export interface CategoriesImages {
  [id: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class CategoriesImagesService {
  constructor(private categoriesMediaService: CategoriesMediaService) {}

  public fetch(categories: Category[]) {
    const images = categories.map((category) => category.image);

    return this.categoriesMediaService.fetchMany(images)
      .pipe(
        map((media) => {
          return media.reduce<CategoriesImages>((acc, item) => {
            acc[item.id] = item.url;

            return acc;
          }, {});
        }),
      );
  }
}
