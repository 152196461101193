export enum InfoType {
  Page = 'page',
  Section = 'section',
  Link = 'link',
}

export interface InfoBase {
  type: InfoType;
  id: string;
  page: string;
  order: number;
  imageUrl: string;
  label: string;
}

export interface InfoPage extends InfoBase {
  type: InfoType.Page;
}

export interface InfoSection extends InfoBase {
  type: InfoType.Section;
  section: string;
}

export interface InfoLink extends InfoBase {
  type: InfoType.Link;
  section: string;
  isLink: boolean;
  payload: string;
  slug: string;
  withGuid: boolean;
}

export type Info = InfoPage | InfoSection | InfoLink;

export interface PageInfoLinks {
  [section: string]: InfoLink[];
}

export interface PageInfo {
  page: InfoPage;
  sections: InfoSection[];
  links: PageInfoLinks;
}
