/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../modules/navbar/navbar.component.ngfactory";
import * as i2 from "../../modules/navbar/navbar.component";
import * as i3 from "@ngrx/store";
import * as i4 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-navbar", [], null, null, null, i1.View_NavbarComponent_0, i1.RenderType_NavbarComponent)), i0.ɵdid(1, 114688, null, 0, i2.NavbarComponent, [i3.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i4.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i0.ɵccf("odn-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
