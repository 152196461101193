import { Injectable, NgZone } from '@angular/core';
import { ApiService, ApiDataResponse } from './api.service';
import { from, merge } from 'rxjs';
import { DataService, Table } from './data.service';
import { Entry, entryInCategory } from '../models/entry';
import { VersionsService } from './versions.service';
import { ListingService, ListingServiceBase } from './listing.service';
import { switchMap } from 'rxjs/operators';

function makeSearchable(text: string) {
  let search = text.toLowerCase();

  search = search.replace(/μπ/g, 'b');
  search = search.replace(/ντ/g, 'd');
  search = search.replace(/ο[υύ]/g, 'ou');
  search = search.replace(/α[υύ]([θκξπσςτχ])/g, 'af$1');
  search = search.replace(/ε[υύ]([θκξπσςτχ$\w])/g, 'ef$1');
  search = search.replace(/α[υύ]/g, 'av');
  search = search.replace(/ε[υύ]/g, 'ev');
  search = search.replace(/(ει|οι|υι)/g, 'i');
  search = search.replace(/αι/g, 'e');
  search = search.replace(/(γκ|γγ)/g, 'g');
  search = search.replace(/[αά]/g, 'a');
  search = search.replace(/β/g, 'v');
  search = search.replace(/γ/g, 'g');
  search = search.replace(/δ/g, 'd');
  search = search.replace(/[εέ]/g, 'e');
  search = search.replace(/ζ/g, 'z');
  search = search.replace(/[ηή]/g, 'i');
  search = search.replace(/θ/g, 'th');
  search = search.replace(/[ιίϊΐ]/g, 'i');
  search = search.replace(/κ/g, 'k');
  search = search.replace(/λ/g, 'l');
  search = search.replace(/μ/g, 'm');
  search = search.replace(/ν/g, 'n');
  search = search.replace(/ξ/g, 'x');
  search = search.replace(/[οό]/g, 'o');
  search = search.replace(/π/g, 'p');
  search = search.replace(/ρ/g, 'r');
  // search = search.replace(/[σς]/g, 's');
  search = search.replace(/[σ]/g, 's');
  search = search.replace(/[ς]/g, '');
  search = search.replace(/τ/g, 't');
  search = search.replace(/[υύϋΰ]/g, 'y');
  search = search.replace(/φ/g, 'f');
  search = search.replace(/χ/g, 'x');
  search = search.replace(/ψ/g, 'ps');
  search = search.replace(/[ωώ]/g, 'o');

  return search;
}

export type EntriesResponse = ApiDataResponse<Entry>;

@Injectable({
  providedIn: 'root',
})
export class EntriesService extends ListingService<Entry, EntriesResponse> {
  constructor(
    apiService: ApiService,
    dataService: DataService,
    versionsService: VersionsService,
    ngZone: NgZone,
  ) {
    super(
      apiService,
      dataService,
      versionsService,
      ngZone,
      Table.Entries,
      ListingServiceBase.Entries,
    );
  }

  public fetchEntriesByCategoryId(categoryId: string) {
    return merge(
      this.getEntriesByCategoryId(categoryId),
      this.updates$.pipe(
        switchMap(() => this.getEntriesByCategoryId(categoryId)),
      ),
    );
  }

  public search(term: string) {
    const words = makeSearchable(term).split(' ').filter(Boolean);

    return from(this.table.filter((entry) => {
      return words.some((word) => {
        return entry.searchable.toLowerCase().includes(word);
      });
    }).toArray());
  }

  public getEntriesByCategoryId(categoryId: string) {
    return from(this.table.filter((entry) => {
      return entryInCategory(entry, categoryId);
    }).toArray());
  }

  public getFeaturedEntries(categoryIds: string[]) {
    return from(this.table.filter((entry) => {
      if (!entry.featured) {
        return false;
      }

      if (!categoryIds) {
        return true;
      }

      return categoryIds.some((categoryId) => entryInCategory(entry, categoryId));
    }).toArray());
  }
}
