import { IconName } from '@fortawesome/free-solid-svg-icons';

export interface IconsMap {
  [key: string]: IconName;
}

export const ICONS_MAP: IconsMap = {
  facebook: 'facebook-f',
  twitter: 'twitter',
  instagram: 'instagram',
  phone: 'phone-volume',
  kinhto: 'mobile',
};
