import { RouterStateSerializer, RouterReducerState } from '@ngrx/router-store';
import { Params, RouterStateSnapshot, Data } from '@angular/router';
import { Installation } from './reducers/installation.reducer';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { storageSync } from '@larscom/ngrx-store-storagesync';

export type AppRouterState = RouterReducerState<RouterStateParams>;

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export interface AppState {
  router: AppRouterState;
  installation: Installation;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  public serialize(routerState: RouterStateSnapshot): RouterStateParams {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    return {
      url: routerState.url,
      params: route.params,
      queryParams: routerState.root.queryParams,
      data: route.data,
    };
  }
}

export function storageSyncReducer(reducer: ActionReducer<AppState>) {
  return storageSync<AppState>({
    features: [
      {
        stateKey: 'installation',
      },
    ],
    storage: window.localStorage,
  })(reducer);
}

export const metaReducers: MetaReducer[] = [
  storageSyncReducer,
];
