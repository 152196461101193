import { createAction, props } from '@ngrx/store';

interface BootstrapProps {
  installationId: string;
}

export enum Actions {
  Bootstrap = '[Manifest] Bootstrap',
}

export const bootstrap = createAction(Actions.Bootstrap, props<BootstrapProps>());
