/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modules/download-content/components/download-notifier/download-notifier.component.ngfactory";
import * as i2 from "./modules/download-content/components/download-notifier/download-notifier.component";
import * as i3 from "@angular/common";
import * as i4 from "./modules/prompt-install/components/prompt-install-notifier/prompt-install-notifier.component.ngfactory";
import * as i5 from "./modules/prompt-install/components/prompt-install-notifier/prompt-install-notifier.component";
import * as i6 from "./components/header/header.component.ngfactory";
import * as i7 from "./components/header/header.component";
import * as i8 from "@angular/router";
import * as i9 from "./components/footer/footer.component.ngfactory";
import * as i10 from "./components/footer/footer.component";
import * as i11 from "./components/notifications/notifications.component.ngfactory";
import * as i12 from "./components/notifications/notifications.component";
import * as i13 from "./services/notification.service";
import * as i14 from "./app.component";
import * as i15 from "./modules/download-content/services/download-content.service";
import * as i16 from "./modules/prompt-install/services/prompt-install.service";
import * as i17 from "@ngrx/store";
import * as i18 from "./services/network.service";
import * as i19 from "./services/match-media.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "odn-download-notifier", [], null, [[null, "confirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirm" === en)) {
        var pd_0 = (_co.onConfirmDownloadNotifier() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DownloadNotifierComponent_0, i1.RenderType_DownloadNotifierComponent)), i0.ɵdid(1, 49152, null, 0, i2.DownloadNotifierComponent, [], { loading: [0, "loading"], online: [1, "online"] }, { confirm: "confirm" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.downloadContentLoading$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.online$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.canDownload$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-prompt-install-notifier", [], null, [[null, "confirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirm" === en)) {
        var pd_0 = (_co.onConfirmPromptInstall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PromptInstallNotifierComponent_0, i4.RenderType_PromptInstallNotifierComponent)), i0.ɵdid(1, 49152, null, 0, i5.PromptInstallNotifierComponent, [], null, { confirm: "confirm" })], null, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppComponent_4)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.promptInstall$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i0.ɵdid(1, 49152, null, 0, i7.HeaderComponent, [i3.Location], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "main", [["class", "py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["promptInstallNotifier", 2]], null, 0, null, View_AppComponent_3)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "odn-footer", [], null, null, null, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i0.ɵdid(10, 49152, null, 0, i10.FooterComponent, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "odn-notifications", [["aria-atomic", "true"], ["aria-live", "polite"]], null, null, null, i11.View_NotificationsComponent_0, i11.RenderType_NotificationsComponent)), i0.ɵdid(12, 49152, null, 0, i12.NotificationsComponent, [i13.NotificationService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = _co.matchMediaService.isStandalone; var currVal_1 = i0.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i14.AppComponent, [i15.DownloadContentService, i16.PromptInstallService, i17.Store, i18.NetworkService, i19.MatchMediaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("odn-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
