import { createAction, props } from '@ngrx/store';
import { MediaResponse } from '../../services/media.service';

export enum Actions {
  Fetch = '[Map Tiles] Fetch',
  UpdateStorage = '[Map Tiles] Update Storage',
}

export const fetch = createAction(Actions.Fetch);

export const updateStorage = createAction(Actions.UpdateStorage, props<MediaResponse>());
