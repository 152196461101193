import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { DownloadContentInitEffects } from './download-content-init.effects';
import { DownloadContentLoadingEffects } from './download-content-loading.effects';
import { DownloadContentEffects } from './download-content.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      DownloadContentInitEffects,
      DownloadContentLoadingEffects,
      DownloadContentEffects,
    ]),
  ],
})
export class DownloadContentEffectsModule {}
