import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Installation } from '../store/reducers/installation.reducer';

@Injectable({
  providedIn: 'root',
})
export class InstallationService {
  private static readonly base = 'installation';

  constructor(private apiService: ApiService) {}

  public fetch() {
    return this.apiService.get<Installation>(InstallationService.base);
  }
}
