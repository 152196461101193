import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export var SocialProvider;
(function (SocialProvider) {
    SocialProvider["Facebook"] = "facebook";
    SocialProvider["FbMessenger"] = "fb-messenger";
})(SocialProvider || (SocialProvider = {}));
export const AVAILABLE_SOCIAL_PROVIDERS = {
    [SocialProvider.Facebook]: {
        sharerUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
        icon: 'facebook-f',
        title: 'Facebook',
    },
    [SocialProvider.FbMessenger]: {
        sharerUrl: 'fb-messenger://share?link=',
        icon: 'facebook-messenger',
        title: 'Facebook Messenger',
    },
};
export class ShareService {
    constructor(document) {
        this.document = document;
    }
    share(socialProvider, url) {
        const shareUrl = `${AVAILABLE_SOCIAL_PROVIDERS[socialProvider].sharerUrl}${encodeURIComponent(url)}`;
        this.document.defaultView.open(shareUrl, 'newwindow');
    }
}
ShareService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShareService_Factory() { return new ShareService(i0.ɵɵinject(i1.DOCUMENT)); }, token: ShareService, providedIn: "root" });
