import { createAction, props } from '@ngrx/store';
import { Installation } from '../reducers/installation.reducer';

export enum Actions {
  Fetch = '[Installation] Fetch',
  Update = '[Installation] Update',
}

export const fetch = createAction(Actions.Fetch);

export const update = createAction(Actions.Update, props<Installation>());
