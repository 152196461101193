import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, OnInitEffects } from '@ngrx/effects';
import * as promptInstallActions from '../actions/prompt-install.actions';
import { switchMap, map, tap, debounceTime, filter, first } from 'rxjs/operators';
import { PromptInstallService } from '../../services/prompt-install.service';
import { MatchMediaService } from '../../../../services/match-media.service';

const WAIT_FOR_MODAL = 1000;

@Injectable()
export class PromptInstallEffects implements OnInitEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(promptInstallActions.init),
      filter(() => !this.matchMediaService.isStandalone),
      debounceTime(WAIT_FOR_MODAL),
      switchMap(() => {
        return this.promptInstallService.promptInstall$
          .pipe(
            first(),
            filter<boolean>(Boolean),
            map(() => promptInstallActions.showModal()),
          );
      }),
    );
  });

  public showModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(promptInstallActions.showModal),
      switchMap(() => {
        return this.promptInstallService.showModal()
          .pipe(
            map(() => promptInstallActions.install()),
          );
      }),
    );
  });

  public install$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(promptInstallActions.install),
      tap(() => {
        this.promptInstallService.install();
      }),
    );
  }, {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private promptInstallService: PromptInstallService,
    private matchMediaService: MatchMediaService,
  ) {}

  public ngrxOnInitEffects() {
    return promptInstallActions.init();
  }
}
