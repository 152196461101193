import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ManifestService {
  private readonly elementId = '#app-manifest';

  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private get origin() {
    return this.document.location.origin;
  }

  public bootstrap(installationId: string) {
    const link = this.renderer.selectRootElement(this.elementId);
    const json = JSON.stringify({
      name: 'Οδηγός Νότια',
      short_name: 'Οδηγός Νότια',
      description: 'Επαγγελματικός κατάλογος Νοτίων Προαστίων',
      lang: 'el-GR',
      theme_color: '#a5cfed',
      background_color: '#e5e5e5',
      display: 'standalone',
      scope: this.origin,
      start_url: `${this.origin}?installation_id=${installationId}`,
      orientation: 'portrait',
      icons: [
        {
          src: `${this.origin}/assets/icons/logo_icon.png`,
          sizes: '512x512',
          type: 'image/png',
        },
      ],
    });
    const blob = new Blob([
      json,
    ], {
      type: 'application/json',
    });

    this.renderer.setAttribute(link, 'href', URL.createObjectURL(blob));
  }
}
