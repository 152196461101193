import { Injectable, NgZone } from '@angular/core';
import { ListingService, ListingServiceBase } from './listing.service';
import { ApiService } from './api.service';
import { DataService, Table } from './data.service';
import { VersionsService } from './versions.service';
import { Info, InfoType, PageInfo, PageInfoLinks, InfoLink, InfoPage, InfoSection } from '../models/info';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

function makePageInfoLinks(links: Info[]) {
  return (links.filter((link) => link.type === InfoType.Link) as InfoLink[])
    .reduce<PageInfoLinks>((acc, link) => {
      acc[link.section] = acc[link.section] || [];
      acc[link.section].push(link);

      return acc;
    }, {});
}

export interface InfoResponse {
  version: string;
  data: Info[];
}

@Injectable({
  providedIn: 'root',
})
export class InfoService extends ListingService<Info, InfoResponse> {
  constructor(
    apiService: ApiService,
    dataService: DataService,
    versionsService: VersionsService,
    ngZone: NgZone,
  ) {
    super(
      apiService,
      dataService,
      versionsService,
      ngZone,
      Table.Info,
      ListingServiceBase.Info,
    );
  }

  public getPageInfo(page: string) {
    return from(this.table.where('page').equals(page).sortBy('order'))
      .pipe(
        map((items): PageInfo => {
          if (!items.length) {
            return;
          }

          return {
            page: items.find((item) => item.type === InfoType.Page) as InfoPage,
            sections: items.filter((item) => item.type === InfoType.Section) as InfoSection[],
            links: makePageInfoLinks(items),
          };
        }),
      );
  }
}
