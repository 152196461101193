import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Entry } from '../../../models/entry';
import { Category } from '../../../models/category';
import { Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-entries-widget',
  templateUrl: 'entries-widget.component.html',
})
export class EntriesWidgetComponent {
  @Input()
  public entries: Entry[];

  @Input()
  public categories: Category[];

  constructor(private router: Router) {}

  public trackByEntryId(index: number, entry: Entry) {
    return entry.id;
  }

  public onSelectEntry(entry: Entry) {
    this.router.navigate(['/entries', entry.id]);
  }

  public onSelectCategory(category: Category) {
    this.router.navigate(['/categories', category.id, 'entries']);
  }

  public showOnMap() {
    this.router.navigate(['/map'], {
      queryParams: {
        entry: this.entries.map((entry) => entry.id),
      },
    });
  }
}
