import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export enum SocialProvider {
  Facebook = 'facebook',
  FbMessenger = 'fb-messenger',
}

export const AVAILABLE_SOCIAL_PROVIDERS = {
  [SocialProvider.Facebook]: {
    sharerUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
    icon: 'facebook-f',
    title: 'Facebook',
  },
  [SocialProvider.FbMessenger]: {
    sharerUrl: 'fb-messenger://share?link=',
    icon: 'facebook-messenger',
    title: 'Facebook Messenger',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public share(socialProvider: SocialProvider, url: string) {
    const shareUrl = `${AVAILABLE_SOCIAL_PROVIDERS[socialProvider].sharerUrl}${encodeURIComponent(url)}`;

    this.document.defaultView.open(shareUrl, 'newwindow');
  }
}
