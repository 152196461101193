import { NgZone } from '@angular/core';
import { ListingService, ListingServiceBase } from './listing.service';
import { ApiService } from './api.service';
import { DataService, Table } from './data.service';
import { VersionsService } from './versions.service';
import { InfoType } from '../models/info';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./data.service";
import * as i3 from "./versions.service";
function makePageInfoLinks(links) {
    return links.filter((link) => link.type === InfoType.Link)
        .reduce((acc, link) => {
        acc[link.section] = acc[link.section] || [];
        acc[link.section].push(link);
        return acc;
    }, {});
}
export class InfoService extends ListingService {
    constructor(apiService, dataService, versionsService, ngZone) {
        super(apiService, dataService, versionsService, ngZone, Table.Info, ListingServiceBase.Info);
    }
    getPageInfo(page) {
        return from(this.table.where('page').equals(page).sortBy('order'))
            .pipe(map((items) => {
            if (!items.length) {
                return;
            }
            return {
                page: items.find((item) => item.type === InfoType.Page),
                sections: items.filter((item) => item.type === InfoType.Section),
                links: makePageInfoLinks(items),
            };
        }));
    }
}
InfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InfoService_Factory() { return new InfoService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.DataService), i0.ɵɵinject(i3.VersionsService), i0.ɵɵinject(i0.NgZone)); }, token: InfoService, providedIn: "root" });
