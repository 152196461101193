import { createAction, props } from '@ngrx/store';
import { MediaResponse } from '../../services/media.service';

export interface FetchSuccessProps {
  response: MediaResponse;
}

export enum Actions {
  Fetch = '[Map Preload] Fetch',
  FetchSuccess = '[Map Preload] Fetch Success',
  UpdateStorage = '[Map Preload] Update Storage',
}

export const fetch = createAction(Actions.Fetch);

export const fetchSuccess = createAction(Actions.FetchSuccess, props<FetchSuccessProps>());

export const updateStorage = createAction(Actions.UpdateStorage, props<FetchSuccessProps>());
