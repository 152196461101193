/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./download-notifier.component";
var styles_DownloadNotifierComponent = [];
var RenderType_DownloadNotifierComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DownloadNotifierComponent, data: {} });
export { RenderType_DownloadNotifierComponent as RenderType_DownloadNotifierComponent };
function View_DownloadNotifierComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["icon", "sync"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(1, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaConfig, i2.FaIconLibrary, [2, i2.FaStackItemSizeDirective]], { icon: [0, "icon"], spin: [1, "spin"] }, null)], function (_ck, _v) { var currVal_2 = "sync"; var currVal_3 = true; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).title; var currVal_1 = i0.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DownloadNotifierComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["icon", "download"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(1, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaConfig, i2.FaIconLibrary, [2, i2.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_2 = "download"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).title; var currVal_1 = i0.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DownloadNotifierComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["aria-label", "\u039B\u03AE\u03C8\u03B7 \u03C0\u03B5\u03C1\u03B9\u03B5\u03C7\u03BF\u03BC\u03AD\u03BD\u03BF\u03C5"], ["class", "btn btn-success odn-btn-circle odn-fixed-bottom-right shadow"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DownloadNotifierComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["downloadIcon", 2]], null, 0, null, View_DownloadNotifierComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; var currVal_2 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading || !_co.online); _ck(_v, 0, 0, currVal_0); }); }
export function View_DownloadNotifierComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-download-notifier", [], null, null, null, View_DownloadNotifierComponent_0, RenderType_DownloadNotifierComponent)), i0.ɵdid(1, 49152, null, 0, i5.DownloadNotifierComponent, [], null, null)], null, null); }
var DownloadNotifierComponentNgFactory = i0.ɵccf("odn-download-notifier", i5.DownloadNotifierComponent, View_DownloadNotifierComponent_Host_0, { loading: "loading", online: "online" }, { confirm: "confirm" }, []);
export { DownloadNotifierComponentNgFactory as DownloadNotifierComponentNgFactory };
