import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as favoritesActions from '../actions/favorites.actions';
import { switchMap, catchError, filter, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { FavoritesService } from '../../services/favorites.service';
import { NetworkService } from '../../services/network.service';
import { Favorite } from '../../models/favorite';
import { Store, select } from '@ngrx/store';
import { AppState } from '..';
import { getInstallationId } from '../selectors/installation.selectors';

@Injectable()
export class FavoritesEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      filter(() => this.networkService.online),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      filter<string>(Boolean),
      map(() => favoritesActions.save()),
    );
  });

  public saveOnOnline$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => {
        return this.networkService.wentOnline$
          .pipe(
            map(() => favoritesActions.save()),
          );
      }),
    );
  });

  public save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoritesActions.save),
      switchMap(() => {
        return this.favoritesService.save()
          .pipe(
            map((response) => favoritesActions.saveSuccess({
              entries: response.entries,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public updateOnSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoritesActions.saveSuccess),
      map((action) => action.entries.map<Favorite>((entry) => ({
        id: entry,
      }))),
      map((favorites) => favoritesActions.update({
        favorites,
      })),
    );
  });

  public update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoritesActions.update),
      switchMap((action) => {
        return this.favoritesService.update(action.favorites)
          .pipe(
            catchError(() => EMPTY),
          );
      }),
    );
  }, {
    dispatch: false,
  });

  public add$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoritesActions.add),
      switchMap((action) => {
        return this.favoritesService.add(action.id)
          .pipe(
            catchError(() => EMPTY),
          );
      }),
    );
  }, {
    dispatch: false,
  });

  public addRemote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoritesActions.add),
      filter(() => this.networkService.online),
      switchMap((action) => {
        return this.favoritesService.addRemote(action.id)
          .pipe(
            catchError(() => EMPTY),
          );
      }),
    );
  }, {
    dispatch: false,
  });

  public remove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoritesActions.remove),
      switchMap((action) => {
        return this.favoritesService.remove(action.id)
          .pipe(
            catchError(() => EMPTY),
          );
      }),
    );
  }, {
    dispatch: false,
  });

  public removeRemote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(favoritesActions.remove),
      filter(() => this.networkService.online),
      switchMap((action) => {
        return this.favoritesService.removeRemote(action.id)
          .pipe(
            catchError(() => EMPTY),
          );
      }),
    );
  }, {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private favoritesService: FavoritesService,
    private networkService: NetworkService,
    private store: Store<AppState>,
  ) {}
}
