type SocialNetworks = 'facebook' | 'twitter';

export type Social = {
  [key in SocialNetworks]: string;
};

type FaxTypes = 'faxtelephone' | string;

export type Fax = {
  [key in FaxTypes]: string;
};

type EmailTypes = 'email' | string;

export type Email = {
  [key in EmailTypes]: string;
};

export enum TelephoneType {
  Phone = 'phone',
  Kinhto = 'kinhto',
}

export type Telephones = {
  [key in TelephoneType | string]: string;
};

export interface WorkHoursRange {
  from: string;
  to: string;
  appointment: boolean;
}

export type WorkHoursDay = WorkHoursRange[];

export type WorkHours = WorkHoursDay[];

export interface Appointment {
  morning: boolean;
  evening: boolean;
}

export interface EntryLocation {
  geo_latitude: number;
  geo_longitude: number;
}

export interface Entry {
  address: string;
  category_ids: string[];
  contact_person?: string;
  description: string;
  emails: Email;
  faxes: Fax;
  featured: boolean;
  gallery: string[];
  id: string;
  location: EntryLocation;
  name: string;
  post_city_id: string;
  searchable: string;
  slug: string;
  social: Social;
  telephones: Telephones;
  website?: string;
  work_hours: WorkHours;
  appointment_only?: Appointment[];
  video?: string;
  video_ratio?: number;
  city_name: string;
}

export function entryInCategory(entry: Entry, categoryId: string) {
  return entry.category_ids.includes(categoryId);
}
