import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-prompt-help-modal',
  templateUrl: 'prompt-help-modal.component.html',
})
export class PromptHelpModalComponent {
  constructor(public bsModalRef: BsModalRef) {}
}
