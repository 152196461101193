import { ChangeDetectionStrategy, Component, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-download-content-modal',
  templateUrl: 'download-content-modal.component.html',
})
export class DownloadContentModalComponent {
  @Output()
  public confirm = new EventEmitter<void>();

  constructor(public bsModalRef: BsModalRef) {}

  public download() {
    this.confirm.emit();
    this.bsModalRef.hide();
  }
}
