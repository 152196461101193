import { EntriesService } from '../../../services/entries.service';
import { map, switchMap } from 'rxjs/operators';
import { EntriesMediaService } from '../../../services/entries-media.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/entries.service";
import * as i2 from "../../../services/entries-media.service";
function selectEntries(entries) {
    if (entries.length < 4) {
        return entries.slice().sort(() => Math.random() - 0.5);
    }
    const result = [];
    do {
        const index = Math.floor(Math.random() * entries.length);
        const entry = entries[index];
        if (!result.includes(entry)) {
            result.push(entry);
        }
    } while (result.length < 3);
    return result;
}
export class BannerService {
    constructor(entriesService, entriesMediaService) {
        this.entriesService = entriesService;
        this.entriesMediaService = entriesMediaService;
    }
    fromCategories(categoryIds) {
        return this.entriesService.getFeaturedEntries(categoryIds)
            .pipe(switchMap((entries) => this.fromEntries(entries)));
    }
    fromEntries(entries) {
        const selected = selectEntries(entries);
        const ids = selected.map((entry) => entry.gallery[0]);
        return this.entriesMediaService.getMany(ids)
            .pipe(map((mediaItems) => {
            if (!mediaItems.length) {
                return [];
            }
            return selected.map((entry) => {
                const mediaItem = mediaItems.find((item) => entry.gallery[0] === item.id);
                return {
                    name: entry.name,
                    id: entry.id,
                    image: mediaItem.url,
                };
            });
        }));
    }
}
BannerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BannerService_Factory() { return new BannerService(i0.ɵɵinject(i1.EntriesService), i0.ɵɵinject(i2.EntriesMediaService)); }, token: BannerService, providedIn: "root" });
