import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as versionsActions from '../actions/versions.actions';
import { switchMap, catchError } from 'rxjs/operators';
import { VersionsService } from '../../services/versions.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class VersionsEffects {
  public update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(versionsActions.update),
      switchMap((action) => {
        return this.versionsService.update({
          id: action.id,
          value: action.value,
        }).pipe(
          catchError(() => EMPTY),
        );
      }),
    );
  }, {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private versionsService: VersionsService,
  ) {}
}
