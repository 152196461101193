import { ChangeDetectionStrategy, Component, Output, EventEmitter } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-prompt-install-notifier',
  templateUrl: 'prompt-install-notifier.component.html',
})
export class PromptInstallNotifierComponent {
  @Output()
  public confirm = new EventEmitter<void>();
}
