import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as entriesActions from '../actions/entries.actions';
import * as versionsActions from '../actions/versions.actions';
import { map, filter, switchMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '..';
import { getInstallationId } from '../selectors/installation.selectors';
import { EntriesService } from '../../services/entries.service';
import { Table } from '../../services/data.service';

@Injectable()
export class EntriesEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      filter<string>(Boolean),
      map(() => entriesActions.fetch()),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesActions.fetch),
      switchMap(() => {
        return this.entriesService.fetch()
          .pipe(
            filter((response) => Boolean(response.data)),
            map((response) => entriesActions.updateStorage(response)),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public updateStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesActions.updateStorage),
      switchMap((action) => {
        return this.entriesService.updateStorage(Object.values(action.data))
          .pipe(
            map(() => versionsActions.update({
              id: Table.Entries,
              value: action.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private entriesService: EntriesService,
    private store: Store<AppState>,
  ) {}
}
