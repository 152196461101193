import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import * as downloadContentActions from '../actions/download-content.actions';
import { map, switchMap, filter, first, debounceTime } from 'rxjs/operators';
import { DownloadContentService } from '../../services/download-content.service';
import { MatchMediaService } from '../../../../services/match-media.service';

const WAIT_FOR_MODAL = 1000;

@Injectable()
export class DownloadContentInitEffects implements OnInitEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.init),
      filter(() => this.matchMediaService.isStandalone),
      debounceTime(WAIT_FOR_MODAL),
      switchMap(() => {
        return this.downloadContentService.canDownload$
          .pipe(
            first(),
            filter<boolean>(Boolean),
            map(() => downloadContentActions.showModal()),
          );
      }),
    );
  });

  public showModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.showModal),
      switchMap(() => {
        return this.downloadContentService.showModal()
          .pipe(
            map(() => downloadContentActions.fetch()),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private downloadContentService: DownloadContentService,
    private matchMediaService: MatchMediaService,
  ) {}

  public ngrxOnInitEffects() {
    return downloadContentActions.init();
  }
}
