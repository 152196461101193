import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-download-notifier',
  templateUrl: 'download-notifier.component.html',
})
export class DownloadNotifierComponent {
  @Input()
  public loading: boolean;

  @Input()
  public online: boolean;

  @Output()
  public confirm = new EventEmitter<void>();
}
