<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Απεγκατάσταση</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="bsModalRef.hide()">
    <fa-icon icon="times" title="Κλείσιμο απεγκατάσταση"></fa-icon>
  </button>
</div>
<div class="modal-body">
  <p><strong>Προσοχή!</strong> Κατά την απεγκατάσταση θα γίνει διαγραφή χρήσιμων δεδομένων, όπως χάρτες, φωτογραφίες καταχωρήσεων, αγαπημένα κ.α.</p>
  <p class="mb-0">Εάν επιθυμείτε να συνεχίσετε με την απεγκατάσταση, πιέστε το κουμπί <strong>"Επιβεβαίωση"</strong>.</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success btn-block" (click)="onConfirm()">Επιβεβαίωση</button>
</div>
