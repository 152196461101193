import { createReducer, Action, on } from '@ngrx/store';
import * as downloadContentActions from '../actions/download-content.actions';

export interface DownloadContent {
  loading: boolean;
}

export const initialState: DownloadContent = {
  loading: false,
};

const reducer = createReducer(
  initialState,
  on(downloadContentActions.loading, (state, action): DownloadContent => ({
    ...state,
    loading: action.loading,
  })),
);

export function downloadContentReducer(state: DownloadContent | undefined, action: Action) {
  return reducer(state, action);
}
