import { NgModule } from '@angular/core';
import { DownloadContentEffectsModule } from './effects/effects.module';
import { StoreModule } from '@ngrx/store';
import { downloadContentReducer } from './reducers/download-content.reducer';

export const DOWNLOAD_CONTENT_STATE_KEY = 'downloadContent';

@NgModule({
  imports: [
    StoreModule.forFeature(DOWNLOAD_CONTENT_STATE_KEY, downloadContentReducer),
    DownloadContentEffectsModule,
  ],
})
export class DownloadContentStoreModule {}
