import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent {
  constructor(private location: Location) {}

  public goBack() {
    this.location.back();
  }
}
