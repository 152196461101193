import { Injectable, NgZone } from '@angular/core';
import { Subject, merge, from } from 'rxjs';
import Dexie from 'dexie';
import { DataService, Table } from './data.service';
import { tap, switchMap, filter } from 'rxjs/operators';
import { Version } from '../models/version';

@Injectable({
  providedIn: 'root',
})
export class VersionsService {
  private static readonly tableName = Table.Versions;

  private table: Dexie.Table<Version, string>;
  private updates = new Subject<Table>();

  constructor(
    private dataService: DataService,
    private ngZone: NgZone,
  ) {
    this.table = this.dataService.table(VersionsService.tableName);
  }

  public get updates$() {
    return this.updates.asObservable();
  }

  public update(version: Version) {
    return from(this.table.put(version))
      .pipe(
        tap((id: Table) => {
          this.ngZone.run(() => {
            this.updates.next(id);
          });
        }),
      );
  }

  public get(id: Table) {
    return from(this.table.get(id));
  }

  public getMany(ids: Table[]) {
    return from(this.table.where('id').anyOf(ids).toArray());
  }

  public removeMany(ids: Table[]) {
    return from(this.table.bulkDelete(ids));
  }

  public fetchOne(id: Table) {
    return merge(
      this.get(id),
      this.updates$.pipe(
        filter((value) => value === id),
        switchMap(() => this.get(id)),
      ),
    );
  }

  public fetchMany(ids: Table[]) {
    return merge(
      this.getMany(ids),
      this.updates$.pipe(
        filter((value) => ids.includes(value)),
        switchMap(() => this.getMany(ids)),
      ),
    );
  }
}
