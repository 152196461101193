import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as downloadContentActions from '../actions/download-content.actions';
import { map, switchMap, filter } from 'rxjs/operators';
import * as versionsActions from '../../../../store/actions/versions.actions';
import { Table } from '../../../../services/data.service';

@Injectable()
export class DownloadContentLoadingEffects {
  public showLoading$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetch, downloadContentActions.remove),
      map(() => downloadContentActions.loading({
        loading: true,
      })),
    );
  });

  // TODO: Create a more accurate logic to hide loading on remove success.
  public hideLoading$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetchFailed, downloadContentActions.removeSuccess),
      map(() => downloadContentActions.loading({
        loading: false,
      })),
    );
  });

  public hideLoadingOnSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadContentActions.fetchSuccess),
      switchMap(() => {
        return this.actions$.pipe(
          ofType(versionsActions.update),
          filter((action) => action.id === Table.EntriesMedia),
          map(() => downloadContentActions.loading({
            loading: false,
          })),
        );
      }),
    );
  });

  constructor(private actions$: Actions) {}
}
