import Dexie from 'dexie';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
export var Table;
(function (Table) {
    Table["Categories"] = "categories";
    Table["Versions"] = "versions";
    Table["Entries"] = "entries";
    Table["EntriesMedia"] = "entriesMedia";
    Table["CategoriesMedia"] = "categoriesMedia";
    Table["MapTiles"] = "mapTiles";
    Table["Favorites"] = "favorites";
    Table["Info"] = "info";
})(Table || (Table = {}));
export class DataService extends Dexie {
    constructor() {
        super('odigosnotia');
        this.createStores();
    }
    reset() {
        return from(this.delete())
            .pipe(switchMap(() => {
            return from(this.open());
        }));
    }
    createStores() {
        this.version(1).stores(DataService.schema);
    }
}
DataService.schema = {
    categories: 'id,name,parent_id,order',
    entries: 'id',
    entriesMedia: 'id',
    categoriesMedia: 'id',
    mapTiles: 'id',
    versions: 'id',
    favorites: 'id',
    info: 'id,page',
};
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
