import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { InstallationEffects } from './installation.effects';
import { CategoriesEffects } from './categories.effects';
import { VersionsEffects } from './versions.effects';
import { EntriesEffects } from './entries.effects';
import { CategoriesMediaEffects } from './categories-media.effects';
import { EntriesMediaEffects } from './entries-media.effects';
import { FavoritesEffects } from './favorites.effects';
import { MapTilesEffects } from './map-tiles.effects';
import { MapPreloadEffects } from './map-preload.effects';
import { InfoEffects } from './info.effects';
import { EntriesMediaPreloadEffects } from './entries-media-preload.effects';
import { ManifestEffects } from './manifest.effects';

@NgModule({
  imports: [
    EffectsModule.forRoot([
      InstallationEffects,
      CategoriesEffects,
      VersionsEffects,
      EntriesEffects,
      CategoriesMediaEffects,
      EntriesMediaEffects,
      EntriesMediaPreloadEffects,
      FavoritesEffects,
      MapTilesEffects,
      MapPreloadEffects,
      InfoEffects,
      ManifestEffects,
    ]),
  ],
})
export class AppEffectsModule {}
