export function getQueryParams() {
  return document.location.search
    .slice(1)
    .split('&')
    .map((param) => param.split('='))
    .reduce<any>((acc, item) => {
      acc[item[0]] = item[1];

      return acc;
    }, {});
}
