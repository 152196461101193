import { Injectable } from '@angular/core';
import { Stats } from './stats.service';
import { DeviceDimensions } from '../service.component';
import { ApiService } from '../../../services/api.service';

export interface SubmitInfoPayload {
  installationId: string;
  agent: string;
  version: string;
  stats: Stats;
  dimensions: DeviceDimensions;
}

@Injectable({
  providedIn: 'root',
})
export class ServiceInfoService {
  public static readonly base = 'instance/service-info';

  constructor(private apiService: ApiService) {}

  public submitInfo(payload: SubmitInfoPayload) {
    return this.apiService.post(ServiceInfoService.base, payload);
  }
}
