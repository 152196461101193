import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesWidgetComponent } from './categories-widget.component';
import { CategoriesWidgetItemComponent } from './components/categories-widget-item/categories-widget-item.component';

@NgModule({
  declarations: [
    CategoriesWidgetComponent,
    CategoriesWidgetItemComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CategoriesWidgetComponent,
  ],
})
export class CategoriesWidgetModule {}
