import { createAction, props } from '@ngrx/store';
import { InfoResponse } from '../../services/info.service';

interface UpdateStorageProps {
  payload: InfoResponse;
}

export enum Actions {
  Fetch = '[Info] Fetch',
  UpdateStorage = '[Info] Update Storage',
}

export const fetch = createAction(Actions.Fetch);

export const updateStorage = createAction(Actions.UpdateStorage, props<UpdateStorageProps>());
