<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Eγκατάσταση</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="bsModalRef.hide()">
    <fa-icon icon="times" title="Κλείσιμο εγκατάστασης"></fa-icon>
  </button>
</div>
<div class="modal-body">
  <p class="text-center"><u>Ευχαριστούμε που χρησιμοποιείται την εφαρμογή "Οδηγός Νότια"</u></p>
  <p>Για να ολοκληρωθεί η εγκατάσταση της εφαρμογής και να έχετε πρόσβαση στις πλήρεις δυνατότητές της θα πρέπει να την εγκαταστήσετε. Αλλιώς μπορείτε να συνεχίσετε να τη χρησιμοποιείτε μέσα από τον browser σας.</p>
  <p class="mb-0"><strong>Μετά την εγκατάσταση, κλείστε τον browser και ανοίξτε τον Οδηγό Νότια από το εικονίδιο της εφαρμογής.</strong></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success flex-grow-1" (click)="onConfirm()">Εγκατάσταση</button>
  <button type="button" class="btn btn-danger flex-grow-1" (click)="bsModalRef.hide()">Αργότερα</button>
</div>
