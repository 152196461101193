import { Injectable, NgZone } from '@angular/core';
import { DataService, Table } from './data.service';
import Dexie from 'dexie';
import { from, Subject, merge } from 'rxjs';
import { Favorite } from '../models/favorite';
import { tap, switchMap, map } from 'rxjs/operators';
import { ApiService } from './api.service';

export interface FavoritesResponse {
  entries: string[];
}

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  public static readonly base = 'instance/favorites';

  private static readonly tableName = Table.Favorites;
  private table: Dexie.Table<Favorite, string>;
  private updates = new Subject<void>();

  constructor(
    private dataService: DataService,
    private ngZone: NgZone,
    private apiService: ApiService,
  ) {
    this.table = this.dataService.table(FavoritesService.tableName);
  }

  private get updates$() {
    return this.updates.asObservable();
  }

  public save() {
    return this.getAll()
      .pipe(
        map((favorites) => favorites.map((favorite) => favorite.id)),
        switchMap((entries) => {
          return this.apiService.post<FavoritesResponse>(FavoritesService.base, {
            entries,
          });
        }),
      );
  }

  public update(favorites: Favorite[]) {
    return from(this.table.bulkAdd(favorites))
      .pipe(
        tap(() => {
          this.ngZone.run(() => {
            this.updates.next();
          });
        }),
      );
  }

  public add(id: string) {
    return from(this.table.add({
      id,
    })).pipe(
      tap(() => {
        this.ngZone.run(() => {
          this.updates.next();
        });
      }),
    );
  }

  public addRemote(id: string) {
    return this.apiService.post(`${FavoritesService.base}/add`, {
      entry: id,
    });
  }

  public remove(id: string) {
    return from(this.table.delete(id))
      .pipe(
        tap(() => {
          this.ngZone.run(() => {
            this.updates.next();
          });
        }),
      );
  }

  public removeRemote(id: string) {
    return this.apiService.post(`${FavoritesService.base}/remove`, {
      entry: id,
    });
  }

  public isFavorite(id: string) {
    return this.fetchOne(id)
      .pipe(
        map(Boolean),
      );
  }

  public getAll() {
    return from(this.table.toArray());
  }

  private fetchOne(id: string) {
    return merge(
      this.getOne(id),
      this.updates$.pipe(
        switchMap(() => this.getOne(id)),
      ),
    );
  }

  private getOne(id: string) {
    return from(this.table.get(id));
  }
}
