import { EntriesResponse } from '../../services/entries.service';
import { createAction, props } from '@ngrx/store';

export enum Actions {
  Fetch = '[Entries] Fetch',
  UpdateStorage = '[Entries] Update Storage',
}

export const fetch = createAction(Actions.Fetch);

export const updateStorage = createAction(Actions.UpdateStorage, props<EntriesResponse>());
