import { DataService, Table } from './data.service';
import { ApiService } from './api.service';
import { MediaService } from './media.service';
import { NgZone } from '@angular/core';
import { VersionsService } from './versions.service';
import { ListingServiceBase } from './listing.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./data.service";
import * as i3 from "./versions.service";
export class CategoriesMediaService extends MediaService {
    constructor(apiService, dataService, versionsService, ngZone) {
        super(apiService, dataService, versionsService, ngZone, Table.CategoriesMedia, ListingServiceBase.CategoriesMedia);
    }
}
CategoriesMediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesMediaService_Factory() { return new CategoriesMediaService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.DataService), i0.ɵɵinject(i3.VersionsService), i0.ɵɵinject(i0.NgZone)); }, token: CategoriesMediaService, providedIn: "root" });
