/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./banner.component";
import * as i4 from "../../services/banner.service";
var styles_BannerComponent = [];
var RenderType_BannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BannerComponent, data: { "animation": [{ type: 7, name: "animateBanner", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "scale(1.2)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "scale(1)" }, offset: null }, timings: 200 }], options: null }], options: {} }] } });
export { RenderType_BannerComponent as RenderType_BannerComponent };
function View_BannerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BannerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_3)), i0.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, "/entries", _v.parent.context.$implicit.id); _ck(_v, 1, 0, currVal_3); var currVal_4 = i0.ɵnov(_v.parent, 4); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_BannerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BannerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_5)), i0.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_2 = i0.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.link; var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BannerComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "odn-img-height"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.image; var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "col-4 px-1 h-100"]], [[24, "@animateBanner", 0], [2, "text-center", null], [2, "text-right", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BannerComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["externalBanner", 2]], null, 0, null, View_BannerComponent_4)), (_l()(), i0.ɵand(0, [["bannerImage", 2]], null, 0, null, View_BannerComponent_6))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.id; var currVal_4 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = (!_v.context.first && !_v.context.last); var currVal_2 = _v.context.last; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_BannerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row mx-n1 justify-content-between flex-grow-1"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BannerComponent_1)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.banners$)); var currVal_1 = _co.trackByBannerId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_BannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-banner", [["class", "d-flex bg-info border-top fixed-bottom p-2 odn-banner"]], null, null, null, View_BannerComponent_0, RenderType_BannerComponent)), i0.ɵdid(1, 4440064, null, 0, i3.BannerComponent, [i2.DOCUMENT, i0.Renderer2, i4.BannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerComponentNgFactory = i0.ɵccf("odn-banner", i3.BannerComponent, View_BannerComponent_Host_0, { entries: "entries", categoryIds: "categoryIds", interval: "interval" }, {}, []);
export { BannerComponentNgFactory as BannerComponentNgFactory };
