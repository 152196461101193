<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Μοιράσου το</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="bsModalRef.hide()">
    <fa-icon icon="times" title="Κλείσιμο κοινής χρήσης"></fa-icon>
  </button>
</div>
<div class="modal-body">
  <a
    *ngFor="let socialProvider of availableSocialProviders | keyvalue"
    class="btn btn-block text-white"
    [ngClass]="'odn-btn-' + socialProvider.key"
    (click)="share(socialProvider.key)">
    <fa-icon [icon]="['fab', socialProvider.value.icon]"></fa-icon>
    <span class="pl-2" [innerHTML]="socialProvider.value.title"></span>
  </a>
</div>
