import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './store/store.module';
import { AppEffectsModule } from './store/effects/effects.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { httpInterceptors } from './interceptors';
import { DownloadContentModule } from './modules/download-content/download-content.module';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CategoriesWidgetModule } from './modules/categories-widget/categories-widget.module';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconModule } from './modules/icon/icon.module';
import { UpdatesModule } from './modules/updates/updates.module';
import { ConfirmResetModalComponent } from './components/confirm-reset-modal/confirm-reset-modal.component';
import { ShareModalComponent } from './components/share-modal/share-modal.component';
import { BannerModule } from './modules/banner/banner.module';
import { PromptInstallModule } from './modules/prompt-install/prompt-install.module';
import { NavbarModule } from './modules/navbar/navbar.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  entryComponents: [
    ConfirmResetModalComponent,
    ShareModalComponent,
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NotificationsComponent,
    HomeComponent,
    ConfirmResetModalComponent,
    ShareModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AppStoreModule,
    AppEffectsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    DownloadContentModule,
    CategoriesWidgetModule,
    SharedModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    IconModule,
    UpdatesModule,
    BannerModule,
    PromptInstallModule,
    NavbarModule,
  ],
  providers: [
    httpInterceptors,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {}
