import { NgZone } from '@angular/core';
import { Subject, merge, from } from 'rxjs';
import { DataService, Table } from './data.service';
import { tap, switchMap, filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
export class VersionsService {
    constructor(dataService, ngZone) {
        this.dataService = dataService;
        this.ngZone = ngZone;
        this.updates = new Subject();
        this.table = this.dataService.table(VersionsService.tableName);
    }
    get updates$() {
        return this.updates.asObservable();
    }
    update(version) {
        return from(this.table.put(version))
            .pipe(tap((id) => {
            this.ngZone.run(() => {
                this.updates.next(id);
            });
        }));
    }
    get(id) {
        return from(this.table.get(id));
    }
    getMany(ids) {
        return from(this.table.where('id').anyOf(ids).toArray());
    }
    removeMany(ids) {
        return from(this.table.bulkDelete(ids));
    }
    fetchOne(id) {
        return merge(this.get(id), this.updates$.pipe(filter((value) => value === id), switchMap(() => this.get(id))));
    }
    fetchMany(ids) {
        return merge(this.getMany(ids), this.updates$.pipe(filter((value) => ids.includes(value)), switchMap(() => this.getMany(ids))));
    }
}
VersionsService.tableName = Table.Versions;
VersionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionsService_Factory() { return new VersionsService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i0.NgZone)); }, token: VersionsService, providedIn: "root" });
