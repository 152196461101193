import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { tap, concatMap, withLatestFrom, filter, map } from 'rxjs/operators';
import * as manifestActions from '../actions/manifest.actions';
import { ManifestService } from '../../services/manifest.service';
import { of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '..';
import { getInstallationId } from '../selectors/installation.selectors';

@Injectable()
export class ManifestEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      filter<string>(Boolean),
      map((installationId) => manifestActions.bootstrap({
        installationId,
      })),
    );
  });

  public boostrap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(manifestActions.bootstrap),
      tap((action) => {
        this.manifestService.bootstrap(action.installationId);
      }),
    );
  }, {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private manifestService: ManifestService,
    private store: Store<AppState>,
  ) {}
}
