import { createReducer, Action, on } from '@ngrx/store';
import * as installationActions from '../actions/installation.actions';

export interface Installation {
  id: string;
}

export const initialState: Installation = {
  id: null,
};

const reducer = createReducer(
  initialState,
  on(installationActions.update, (state, action): Installation => ({
    ...state,
    id: action.id,
  })),
);

export function installationReducer(state: Installation | undefined, action: Action) {
  return reducer(state, action);
}
