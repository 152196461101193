<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Λήψη περιεχομένου</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="bsModalRef.hide()">
    <fa-icon icon="times" title="Κλείσιμο λήψης περιεχομένου"></fa-icon>
  </button>
</div>
<div class="modal-body">
  <p>Η εφαρμογή αυτή είναι σχεδιασμένη να λειτουργεί πλήρως χωρίς να απαιτεί διαρκή σύνδεση με το Internet. Για να γίνει αυτό θα πρέπει να κατεβάσετε το offline περιεχόμενο. Σε αντίθετη περίπτωση, όταν είστε offline θα λείπουν κάποιες λειτουργίες της.</p>
  <p class="mb-0">Σας συνιστούμε να κάνετε λήψη μέσω <strong>WiFi</strong> ώστε να αποφύγετε τη χρέωση των δεδομένων από τον πάροχό σας.</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success flex-grow-1" (click)="download()">Κατεβάστε τώρα</button>
  <button type="button" class="btn btn-danger flex-grow-1" (click)="bsModalRef.hide()">Αργότερα</button>
</div>
