<nav class="navbar navbar-expand navbar-light fixed-bottom bg-white p-0 border-top">
  <ul class="navbar-nav flex-fill text-center">
    <li class="nav-item flex-fill" *ngFor="let navItem of navItems">
      <a class="nav-link p-0" [routerLink]="navItem.commands" [title]="navItem.title">
        <img
          [src]="'assets/' + navItem.icon + '.svg'"
          width="40px"
          height="40px"
          [alt]="navItem.title" />
      </a>
    </li>
    <li class="nav-item flex-fill btn-group" dropdown [dropup]="true" placement="top right">
      <button
        type="button"
        dropdownToggle
        id="button-dropup"
        class="btn btn-link nav-link p-0 border-0"
        title="Περισσότερα">
        <img
          src="assets/burger.svg"
          width="40px"
          height="40px"
          alt="Περισσότερα" />
      </button>
      <ul
        *dropdownMenu
        id="dropdown-dropup"
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-dropup">
        <li class="nav-item" *ngFor="let subNavItem of subNavItems">
          <a
            *ngIf="subNavItem.link; else internalLink"
            class="nav-link"
            [href]="subNavItem.link | linkPageReplace:(routerDocLink$ | async)"
            target="_blank"
            [title]="subNavItem.title">
            <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
          </a>
          <ng-template #internalLink>
            <a
              class="nav-link"
              [routerLink]="subNavItem.commands"
              [title]="subNavItem.title">
              <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
            </a>
          </ng-template>
          <ng-template #linkContent>
            <img
              [src]="'assets/' + subNavItem.icon + '.svg'"
              width="40px"
              height="40px"
              [alt]="subNavItem.title" />
            {{ subNavItem.title }}
          </ng-template>
        </li>
      </ul>
    </li>
  </ul>
</nav>
