import { Injectable, NgZone } from '@angular/core';
import { ApiService, ApiDataResponse } from './api.service';
import { merge, from, EMPTY } from 'rxjs';
import { Category } from '../models/category';
import { DataService, Table } from './data.service';
import { switchMap } from 'rxjs/operators';
import { VersionsService } from './versions.service';
import { ListingService, ListingServiceBase } from './listing.service';

export type CategoriesResponse = ApiDataResponse<Category>;

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends ListingService<Category, CategoriesResponse> {
  constructor(
    apiService: ApiService,
    dataService: DataService,
    versionsService: VersionsService,
    ngZone: NgZone,
  ) {
    super(
      apiService,
      dataService,
      versionsService,
      ngZone,
      Table.Categories,
      ListingServiceBase.Categories,
    );
  }

  public fetchCategoriesByParentId(parentId = '0') {
    return merge(
      this.getCategoriesByParentId(parentId),
      this.updates$.pipe(
        switchMap(() => this.getCategoriesByParentId(parentId)),
      ),
    );
  }

  public fetchParent(id: string) {
    return merge(
      this.getParent(id),
      this.updates$.pipe(
        switchMap(() => this.getParent(id)),
      ),
    );
  }

  public getCategoriesByParentId(parentId = '0') {
    return from(this.table.where('parent_id').equals(parentId).sortBy('order'));
  }

  public getParent(id: string) {
    return this.getOne(id)
      .pipe(
        switchMap((category) => {
          if (!category) {
            return EMPTY;
          }

          return this.getOne(category.parent_id);
        }),
      );
  }
}
