<div class="row mt-n2 mx-n2 no-gutters odn-overflow-hidden">
  <ng-container *ngFor="let category of categories; let i = index; trackBy: trackByCategoryId">
    <div *ngIf="index && i === index" class="col-4 mt-2 text-center">
      <ng-content></ng-content>
    </div>
    <div
      class="col-4 mt-2 text-center">
      <odn-categories-widget-item
        [category]="category"
        [image]="categoriesImages && categoriesImages[category.image] || fallbackCategoryImage"
        (click)="selectCategory.emit(category)">
      </odn-categories-widget-item>
    </div>
  </ng-container>
</div>
