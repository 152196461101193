import { NgZone } from '@angular/core';
import { DataService, Table } from './data.service';
import { from, Subject, merge } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "./api.service";
export class FavoritesService {
    constructor(dataService, ngZone, apiService) {
        this.dataService = dataService;
        this.ngZone = ngZone;
        this.apiService = apiService;
        this.updates = new Subject();
        this.table = this.dataService.table(FavoritesService.tableName);
    }
    get updates$() {
        return this.updates.asObservable();
    }
    save() {
        return this.getAll()
            .pipe(map((favorites) => favorites.map((favorite) => favorite.id)), switchMap((entries) => {
            return this.apiService.post(FavoritesService.base, {
                entries,
            });
        }));
    }
    update(favorites) {
        return from(this.table.bulkAdd(favorites))
            .pipe(tap(() => {
            this.ngZone.run(() => {
                this.updates.next();
            });
        }));
    }
    add(id) {
        return from(this.table.add({
            id,
        })).pipe(tap(() => {
            this.ngZone.run(() => {
                this.updates.next();
            });
        }));
    }
    addRemote(id) {
        return this.apiService.post(`${FavoritesService.base}/add`, {
            entry: id,
        });
    }
    remove(id) {
        return from(this.table.delete(id))
            .pipe(tap(() => {
            this.ngZone.run(() => {
                this.updates.next();
            });
        }));
    }
    removeRemote(id) {
        return this.apiService.post(`${FavoritesService.base}/remove`, {
            entry: id,
        });
    }
    isFavorite(id) {
        return this.fetchOne(id)
            .pipe(map(Boolean));
    }
    getAll() {
        return from(this.table.toArray());
    }
    fetchOne(id) {
        return merge(this.getOne(id), this.updates$.pipe(switchMap(() => this.getOne(id))));
    }
    getOne(id) {
        return from(this.table.get(id));
    }
}
FavoritesService.base = 'instance/favorites';
FavoritesService.tableName = Table.Favorites;
FavoritesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FavoritesService_Factory() { return new FavoritesService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.ApiService)); }, token: FavoritesService, providedIn: "root" });
