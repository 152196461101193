<nav class="navbar navbar-light bg-white border-bottom">
  <button
    type="button"
    class="btn btn-link position-absolute p-0 border-0 odn-navbar-top-left"
    (click)="goBack()">
    <img src="assets/gestures.svg" height="40px" alt="Πίσω" />
  </button>
  <a class="navbar-brand p-0 mx-auto" routerLink="/">
    <img src="assets/logo.svg" height="40px" alt="Οδηγός Νότια" />
  </a>
  <a
    routerLink="/search"
    title="Αναζήτηση"
    class="position-absolute odn-navbar-top-right">
    <img src="assets/search.svg" height="40px" alt="Αναζήτηση" />
  </a>
</nav>
