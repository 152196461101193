import { ApiService } from '../../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api.service";
export class ServiceInfoService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    submitInfo(payload) {
        return this.apiService.post(ServiceInfoService.base, payload);
    }
}
ServiceInfoService.base = 'instance/service-info';
ServiceInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServiceInfoService_Factory() { return new ServiceInfoService(i0.ɵɵinject(i1.ApiService)); }, token: ServiceInfoService, providedIn: "root" });
