/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../modules/categories-widget/categories-widget.component.ngfactory";
import * as i2 from "../../modules/categories-widget/categories-widget.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../modules/banner/components/banner/banner.component.ngfactory";
import * as i6 from "../../modules/banner/components/banner/banner.component";
import * as i7 from "../../modules/banner/services/banner.service";
import * as i8 from "./home.component";
import * as i9 from "../../services/categories.service";
import * as i10 from "../../services/categories-images.service";
var styles_HomeComponent = [];
var RenderType_HomeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "odn-categories-widget", [], null, [[null, "selectCategory"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectCategory" === en)) {
        var pd_0 = (_co.onSelectCategory($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CategoriesWidgetComponent_0, i1.RenderType_CategoriesWidgetComponent)), i0.ɵdid(1, 49152, null, 0, i2.CategoriesWidgetComponent, [], { categories: [0, "categories"], categoriesImages: [1, "categoriesImages"], index: [2, "index"] }, { selectCategory: "selectCategory" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "a", [["class", "btn btn-lg border-primary bg-info odn-btn-circle d-flex justify-content-center align-items-center mx-auto"], ["routerLink", "/search"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["alt", "\u0391\u03BD\u03B1\u03B6\u03AE\u03C4\u03B7\u03C3\u03B7"], ["height", "40px"], ["src", "assets/search.svg"], ["width", "40px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "p", [["class", "text-sm mb-0 mt-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0391\u03BD\u03B1\u03B6\u03AE\u03C4\u03B7\u03C3\u03B7"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "odn-banner", [["class", "d-flex bg-info border-top fixed-bottom p-2 odn-banner"]], null, null, null, i5.View_BannerComponent_0, i5.RenderType_BannerComponent)), i0.ɵdid(10, 4440064, null, 0, i6.BannerComponent, [i3.DOCUMENT, i0.Renderer2, i7.BannerService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.categories$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.categoriesImages$)); var currVal_2 = 4; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = "/search"; _ck(_v, 5, 0, currVal_5); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 5).target; var currVal_4 = i0.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_HomeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i0.ɵdid(1, 114688, null, 0, i8.HomeComponent, [i9.CategoriesService, i4.Router, i10.CategoriesImagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i0.ɵccf("odn-home", i8.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
