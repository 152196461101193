import { DataService, Table } from './data.service';
import { ApiService } from './api.service';
import { MediaService } from './media.service';
import { NgZone } from '@angular/core';
import { VersionsService } from './versions.service';
import { ListingServiceBase } from './listing.service';
import { from } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./data.service";
import * as i3 from "./versions.service";
export class MapTilesService extends MediaService {
    constructor(apiService, dataService, versionsService, ngZone) {
        super(apiService, dataService, versionsService, ngZone, Table.MapTiles, ListingServiceBase.MapTiles);
    }
    clearPartial() {
        return from(this.table.where('id').startsWithAnyOf(['15-', '16-', '17-']).delete());
    }
}
MapTilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapTilesService_Factory() { return new MapTilesService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.DataService), i0.ɵɵinject(i3.VersionsService), i0.ɵɵinject(i0.NgZone)); }, token: MapTilesService, providedIn: "root" });
