import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const VERSION_HEADER = 'Odn-Collection-Version';
export const INSTALLATION_HEADER = 'Odn-Installation-Guid';
export const CLIENT_VERSION_HEADER = 'Odn-Client-Version';
export class ApiService {
    constructor(http) {
        this.http = http;
    }
    get(url, options) {
        return this.http.get(`${ApiService.base}/${url}`, options)
            .pipe(catchError(this.onError));
    }
    post(url, payload) {
        return this.http.post(`${ApiService.base}/${url}`, payload)
            .pipe(catchError(this.onError));
    }
    onError(response) {
        return throwError(response);
    }
}
ApiService.base = environment.api.base;
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
