import { Renderer2, RendererFactory2 } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class ManifestService {
    constructor(document, rendererFactory) {
        this.document = document;
        this.rendererFactory = rendererFactory;
        this.elementId = '#app-manifest';
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }
    get origin() {
        return this.document.location.origin;
    }
    bootstrap(installationId) {
        const link = this.renderer.selectRootElement(this.elementId);
        const json = JSON.stringify({
            name: 'Οδηγός Νότια',
            short_name: 'Οδηγός Νότια',
            description: 'Επαγγελματικός κατάλογος Νοτίων Προαστίων',
            lang: 'el-GR',
            theme_color: '#a5cfed',
            background_color: '#e5e5e5',
            display: 'standalone',
            scope: this.origin,
            start_url: `${this.origin}?installation_id=${installationId}`,
            orientation: 'portrait',
            icons: [
                {
                    src: `${this.origin}/assets/icons/logo_icon.png`,
                    sizes: '512x512',
                    type: 'image/png',
                },
            ],
        });
        const blob = new Blob([
            json,
        ], {
            type: 'application/json',
        });
        this.renderer.setAttribute(link, 'href', URL.createObjectURL(blob));
    }
}
ManifestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManifestService_Factory() { return new ManifestService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i0.RendererFactory2)); }, token: ManifestService, providedIn: "root" });
