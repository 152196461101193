import { createAction, props } from '@ngrx/store';
import { Favorite } from '../../models/favorite';

export interface AddRemoveProps {
  id: string;
}

export interface SaveSuccessProps {
  entries: string[];
}

export interface UpdateProps {
  favorites: Favorite[];
}

export enum Actions {
  Save = '[Favorites] Save',
  SaveSuccess = '[Favorites] SaveSuccess',
  Update = '[Favorites] Update',
  Add = '[Favorites] Add',
  Remove = '[Favorites] Remove',
}

export const save = createAction(Actions.Save);

export const saveSuccess = createAction(Actions.SaveSuccess, props<SaveSuccessProps>());

export const update = createAction(Actions.Update, props<UpdateProps>());

export const add = createAction(Actions.Add, props<AddRemoveProps>());

export const remove = createAction(Actions.Remove, props<AddRemoveProps>());
