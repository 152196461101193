import { MediaResponse } from '../../services/media.service';
import { createAction, props } from '@ngrx/store';

export enum Actions {
  Fetch = '[Media Entries] Fetch',
  UpdateStorage = '[Media Entries] Update Storage',
}

export const fetch = createAction(Actions.Fetch);

export const updateStorage = createAction(Actions.UpdateStorage, props<MediaResponse>());
