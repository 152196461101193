import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as entriesMediaPreloadActions from '../actions/entries-media-preload.actions';
import * as versionsActions from '../actions/versions.actions';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getInstallationId } from '../selectors/installation.selectors';
import { AppState } from '..';
import { EntriesMediaPreloadService } from '../../services/entries-media-preload.service';
import { Table } from '../../services/data.service';

@Injectable()
export class EntriesMediaPreloadEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      switchMap((installationId) => {
        return this.entriesMediaPreloadService.count()
          .pipe(
            filter((count) => Boolean(installationId && !count)),
            map(() => entriesMediaPreloadActions.fetch()),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesMediaPreloadActions.fetch),
      switchMap(() => {
        return this.entriesMediaPreloadService.fetch()
          .pipe(
            map((response) => entriesMediaPreloadActions.fetchSuccess({
              response,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public fetchSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesMediaPreloadActions.fetchSuccess),
      filter((action) => Boolean(action.response.data)),
      map((action) => entriesMediaPreloadActions.updateStorage({
        response: action.response,
      })),
    );
  });

  public handleAdditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(entriesMediaPreloadActions.updateStorage),
      map((action) => action.response),
      filter((response) => Boolean(Object.keys(response.data.additions).length)),
      switchMap((response) => {
        return this.entriesMediaPreloadService.handleAdditions(response.data.additions, response.data.partial)
          .pipe(
            map(() => versionsActions.update({
              id: Table.EntriesMedia,
              value: response.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private entriesMediaPreloadService: EntriesMediaPreloadService,
  ) {}
}
