import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneCleanup',
})
export class PhoneCleanupPipe implements PipeTransform {
  public transform(value: string) {
    return value.split(/[ .-]/).join('');
  }
}
