<div class="row mx-n1 justify-content-between flex-grow-1">
  <div
    @animateBanner
    class="col-4 px-1 h-100"
    *ngFor="let banner of banners$ | async; let f = first; let l = last; trackBy: trackByBannerId"
    [class.text-center]="!f && !l"
    [class.text-right]="l">
    <a
      *ngIf="banner.id; else externalBanner"
      [title]="banner.name"
      [routerLink]="['/entries', banner.id]">
      <ng-container *ngTemplateOutlet="bannerImage"></ng-container>
    </a>
    <ng-template #externalBanner>
      <a [href]="banner.link" target="_blank" [title]="banner.name">
        <ng-container *ngTemplateOutlet="bannerImage"></ng-container>
      </a>
    </ng-template>
    <ng-template #bannerImage>
      <img class="odn-img-height" [src]="banner.image" [alt]="banner.name" />
    </ng-template>
  </div>
</div>
