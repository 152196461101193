import { Pipe, PipeTransform } from '@angular/core';
import { Entry } from '../../../models/entry';
import { CategoriesMap } from '../../../services/entries-categories.service';

@Pipe({
  name: 'entryCategories',
})
export class EntryCategoriesPipe implements PipeTransform {
  public transform(entry: Entry, categories: CategoriesMap) {
    if (!categories) {
      return;
    }

    return entry.category_ids.map((id) => categories[id]);
  }
}
