<button
  type="button"
  class="btn btn-success odn-btn-circle odn-fixed-bottom-right shadow"
  (click)="confirm.emit()"
  aria-label="Λήψη περιεχομένου"
  [disabled]="loading || !online">
  <fa-icon *ngIf="loading; else downloadIcon" icon="sync" [spin]="true"></fa-icon>
  <ng-template #downloadIcon>
    <fa-icon icon="download"></fa-icon>
  </ng-template>
</button>
