import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PromptInstallModalComponent } from '../components/prompt-install-modal/prompt-install-modal.component';
import { PromptHelpModalComponent } from '../components/prompt-help-modal/prompt-help-modal.component';

enum UserChoiceOutcome {
  Accepted = 'accepted',
  Dismissed = 'dismissed',
}

interface UserChoice {
  outcome: UserChoiceOutcome;
  platform: string;
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<UserChoice>;
  prompt(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class PromptInstallService implements OnDestroy {
  public canPromptInstall = ('onbeforeinstallprompt' in window);

  private event: BeforeInstallPromptEvent;
  private promptInstall = new BehaviorSubject(!this.canPromptInstall);

  constructor(private bsModalService: BsModalService) {}

  public get promptInstall$() {
    return this.promptInstall.asObservable();
  }

  public ngOnDestroy() {
    this.event = null;
    this.promptInstall.complete();

    this.removeListeners();
  }

  public bootstrap() {
    this.handleBeforeInstallPrompt = this.handleBeforeInstallPrompt.bind(this);
    this.handleAppInstalled = this.handleAppInstalled.bind(this);

    this.addListeners();
  }

  public prompt() {
    if (!this.event) {
      return;
    }

    this.event.prompt();

    this.event.userChoice
      .then(() => {
        this.event = null;
        this.promptInstall.next(false);
      });
  }

  public showModal() {
    const modal = this.bsModalService.show(PromptInstallModalComponent);

    return (modal.content as PromptInstallModalComponent).confirm;
  }

  public install() {
    if (this.canPromptInstall) {
      return this.prompt();
    }

    this.bsModalService.show(PromptHelpModalComponent);
  }

  private addListeners() {
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', this.handleAppInstalled);
  }

  private removeListeners() {
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.removeEventListener('appinstalled', this.handleAppInstalled);
  }

  private handleBeforeInstallPrompt(event: BeforeInstallPromptEvent) {
    this.event = event;
    this.promptInstall.next(true);
  }

  private handleAppInstalled() {
    this.event = null;
    this.promptInstall.next(false);
  }
}
