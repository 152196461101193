import { NgZone } from '@angular/core';
import { from, Subject, merge } from 'rxjs';
import Dexie from 'dexie';
import { DataService, Table } from './data.service';
import { VersionsService } from './versions.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { GetRequestOptions, VERSION_HEADER, ApiService } from './api.service';

export enum ListingServiceBase {
  Categories = 'listing/categories',
  Entries = 'listing/entries',
  CategoriesMedia = 'listing/media/categories',
  EntriesMedia = 'listing/media/entries',
  EntriesMediaPreload = 'listing/media/entries/preload',
  MapPreload = 'map/preload',
  MapTiles = 'map/tiles',
  Info = 'generic/info',
}

export abstract class ListingService<D, R> {
  protected table: Dexie.Table<D, string>;

  private updates = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private versionsService: VersionsService,
    private ngZone: NgZone,
    protected readonly tableName: Table,
    protected readonly base: ListingServiceBase,
  ) {
    this.table = this.dataService.table(this.tableName);
  }

  protected get updates$() {
    return this.updates.asObservable();
  }

  public fetchClean() {
    return this.apiService.get<R>(this.base);
  }

  public fetch() {
    return this.versionsService.get(this.tableName)
      .pipe(
        map((version) => {
          const options: GetRequestOptions = {};

          if (version) {
            options.headers = {
              [VERSION_HEADER]: version.value,
            };
          }

          return options;
        }),
        switchMap((options) => {
          return this.apiService.get<R>(this.base, options);
        }),
      );
  }

  public fetchOne(id: string) {
    return merge(
      this.getOne(id),
      this.updates$.pipe(
        switchMap(() => this.getOne(id)),
      ),
    );
  }

  public fetchMany(ids: string[]) {
    return merge(
      this.getMany(ids),
      this.updates$.pipe(
        switchMap(() => this.getMany(ids)),
      ),
    );
  }

  public updateStorage(data: D[]) {
    return this.clear()
      .pipe(
        switchMap(() => this.storeMany(data)),
        tap(() => {
          this.ngZone.run(() => {
            this.updates.next();
          });
        }),
      );
  }

  public count() {
    return from(this.table.count());
  }

  public getOne(id: string) {
    return from(this.table.get(id));
  }

  public getMany(ids: string[]) {
    return from(this.table.where('id').anyOf(ids).toArray());
  }

  public storeMany(data: D[]) {
    return from(this.table.bulkPut(data));
  }

  public clear() {
    return from(this.table.clear());
  }
}
