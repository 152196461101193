<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Εγκατάσταση</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="bsModalRef.hide()">
    <fa-icon icon="times" title="Κλείσιμο εγκατάστασης"></fa-icon>
  </button>
</div>
<div class="modal-body">
  <p>Μπορείτε να εγκαταστήσετε την εφαρμογή στο κινητό σας τηλέφωνο, κάνοντας κλικ στο "Share icon"</p>
  <p><img class="img-fluid" src="assets/add-to-home-screen/ios_share.png"></p>
  <p>και στη συνέχεια στο "Add to Home Screen".</p>
  <p class="mb-0"><img class="img-fluid" src="assets/add-to-home-screen/ios_add_to_home_screen.png"></p>
</div>
