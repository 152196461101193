import { DataService, Table } from './data.service';
import { from, EMPTY } from 'rxjs';
import { ApiService } from './api.service';
import { MediaItem } from '../models/media';
import { VersionsService } from './versions.service';
import { ListingService, ListingServiceBase } from './listing.service';
import { NgZone } from '@angular/core';

export interface MediaDataAdditions {
  [id: string]: string;
}

export interface MediaData {
  additions: MediaDataAdditions;
  deletions: string[];
  partial: boolean;
  from: string;
  source: 'cache';
}

export interface MediaResponse {
  version: string;
  data: MediaData;
}

export abstract class MediaService extends ListingService<MediaItem, MediaResponse> {
  protected constructor(
    apiService: ApiService,
    dataService: DataService,
    versionsService: VersionsService,
    ngZone: NgZone,
    tableName: Table,
    base: ListingServiceBase,
  ) {
    super(
      apiService,
      dataService,
      versionsService,
      ngZone,
      tableName,
      base,
    );
  }

  public handleAdditions(additions: MediaDataAdditions, partial: boolean) {
    const keys = Object.keys(additions);

    if (!keys.length) {
      return EMPTY;
    }

    const items = keys.map<MediaItem>((item) => ({
      id: item,
      url: additions[item],
    }));

    return partial ? this.storeMany(items) : this.updateStorage(items);
  }

  public handleDeletions(deletions: string[]) {
    if (!deletions.length) {
      return EMPTY;
    }

    return this.deleteMany(deletions);
  }

  public deleteMany(ids: string[]) {
    return from(this.table.bulkDelete(ids));
  }
}
