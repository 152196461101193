import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class InstallationService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    fetch() {
        return this.apiService.get(InstallationService.base);
    }
}
InstallationService.base = 'installation';
InstallationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InstallationService_Factory() { return new InstallationService(i0.ɵɵinject(i1.ApiService)); }, token: InstallationService, providedIn: "root" });
