import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store';
import { getRouterDocLink } from '../../store/selectors/router.selectors';

interface NavItem {
  title: string;
  icon: string;
  commands?: string[];
  link?: string;
}

export const PAGE_REPLACE_PATTERN = '##PAGE##';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-navbar',
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent implements OnInit {
  public navItems: NavItem[] = [{
    commands: ['/'],
    title: 'Αρχική σελίδα',
    icon: 'home',
  }, {
    commands: ['/info/city'],
    title: 'Πληροφορίες πόλεων',
    icon: 'world_monument',
  }, {
    commands: ['/info/odninfo'],
    title: 'Πληροφορίες οδηγού',
    icon: 'opera',
  }, {
    commands: ['/favorites'],
    title: 'Αγαπημένα',
    icon: 'heart',
  }];
  public subNavItems: NavItem[] = [{
    link: `https://docs.app.odigosnotia.gr/${process.env.VERSION}/${PAGE_REPLACE_PATTERN}`,
    title: 'Οδηγίες',
    icon: 'book',
  }, {
    commands: ['/service'],
    title: 'Τεχνικά',
    icon: 'settings',
  }];
  public routerDocLink$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  public ngOnInit() {
    this.routerDocLink$ = this.store.pipe(select(getRouterDocLink));
  }
}
