import { Component, ChangeDetectionStrategy, AfterViewInit, Renderer2, Inject, OnDestroy, OnInit, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BannerService, Banner } from '../../services/banner.service';
import { Observable, timer } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';
import { Entry } from '../../../../models/entry';
import { bannerAnimation } from '../../animations/banner.animation';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-banner',
  templateUrl: 'banner.component.html',
  host: {
    class: 'd-flex bg-info border-top fixed-bottom p-2 odn-banner',
  },
  animations: [
    bannerAnimation(),
  ],
})
export class BannerComponent implements OnInit, AfterViewInit, OnDestroy {
  public banners$: Observable<Banner[]>;

  private readonly bodyClass = 'odn-body--with-banner';

  private defaultBanners: Banner[] = [{
    image: 'assets/banners/szdesign.png',
    name: 'SZDesign',
    id: '300',
  }, {
    image: 'assets/banners/ilioupolis.png',
    name: 'Ilioupolis',
    link: 'http://www.ilioupolis.gr',
  }, {
    image: 'assets/banners/szwdesign.jpg',
    name: 'SZDWesign',
    id: '1421',
  }];

  @Input()
  private entries: Entry[];

  @Input()
  private categoryIds: string[];

  @Input()
  private interval = 5000;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private bannerService: BannerService,
  ) {}

  public ngOnInit() {
    this.banners$ = timer(0, this.interval)
      .pipe(
        concatMap(() => {
          return this.getBanners()
            .pipe(
              map((banners) => {
                return this.defaultBanners.map((defaultItem, index) => {
                  return banners[index] || defaultItem;
                });
              }),
            );
        }),
      );
  }

  public ngAfterViewInit() {
    this.renderer.addClass(this.document.body, this.bodyClass);
  }

  public ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.bodyClass);
  }

  public trackByBannerId(index: number, banner: Banner) {
    return banner.id ? `${index}.${banner.id}` : index;
  }

  private getBanners() {
    if (this.entries) {
      return this.bannerService.fromEntries(this.entries);
    }

    return this.bannerService.fromCategories(this.categoryIds);
  }
}
