import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Entry } from '../../../models/entry';
import { Category } from '../../../models/category';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-entry-widget',
  templateUrl: 'entry-widget.component.html',
})
export class EntryWidgetComponent {
  @Input()
  public entry: Entry;

  @Input()
  public categories: Category[];

  @Output()
  public selectCategory = new EventEmitter<Category>();

  public get phone() {
    return this.entry.telephones.phone || this.entry.telephones.kinhto;
  }

  public onClickCategory(event: MouseEvent, category: Category) {
    event.stopPropagation();
    this.selectCategory.emit(category);
  }

  public trackByCategoryId(index: number, category: Category) {
    return category.id;
  }
}
