import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EntryWidgetComponent } from './components/entry-widget/entry-widget.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { EntryCategoriesPipe } from './pipes/entry-categories/entry-categories.pipe';
import { SafePipe } from './pipes/safe/safe.pipe';
import { PhoneCleanupPipe } from './pipes/phone-cleanup/phone-cleanup.pipe';
import { EntriesWidgetComponent } from './components/entries-widget/entries-widget.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FontAwesomeModule,
  RouterModule,
  NgbToastModule,
  TabsModule,
];

const COMPONENTS = [
  EntriesWidgetComponent,
  EntryWidgetComponent,
];

const PIPES = [
  EntryCategoriesPipe,
  PhoneCleanupPipe,
  SafePipe,
];

@NgModule({
  declarations: [
    COMPONENTS,
    PIPES,
  ],
  imports: [
    MODULES,
  ],
  exports: [
    MODULES,
    COMPONENTS,
    PIPES,
  ],
})
export class SharedModule {}
