import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [{
  path: 'info',
  loadChildren: () => import('./features/info/info.module').then((m) => m.InfoModule),
}, {
  path: 'categories',
  loadChildren: () => import('./features/categories/categories.module').then((m) => m.CategoriesModule),
}, {
  path: 'search',
  loadChildren: () => import('./features/search/search.module').then((m) => m.SearchModule),
}, {
  path: 'entries',
  loadChildren: () => import('./features/entries/entries.module').then((m) => m.EntriesModule),
}, {
  path: 'map',
  loadChildren: () => import('./features/map/map.module').then((m) => m.MapModule),
}, {
  path: 'favorites',
  loadChildren: () => import('./features/favorites/favorites.module').then((m) => m.FavoritesModule),
}, {
  path: 'service',
  loadChildren: () => import('./features/service/service.module').then((m) => m.ServiceModule),
}, {
  path: '',
  component: HomeComponent,
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {}
