import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as mapPreloadActions from '../actions/map-preload.actions';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { MapPreloadService } from '../../services/map-preload.service';
import { select, Store } from '@ngrx/store';
import { getInstallationId } from '../selectors/installation.selectors';
import { AppState } from '..';

@Injectable()
export class MapPreloadEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      switchMap((installationId) => {
        return this.mapPreloadService.count()
          .pipe(
            filter((count) => Boolean(installationId && !count)),
            map(() => mapPreloadActions.fetch()),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mapPreloadActions.fetch),
      switchMap(() => {
        return this.mapPreloadService.fetch()
          .pipe(
            map((response) => mapPreloadActions.fetchSuccess({
              response,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public fetchSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mapPreloadActions.fetchSuccess),
      filter((action) => Boolean(action.response.data)),
      map((action) => mapPreloadActions.updateStorage({
        response: action.response,
      })),
    );
  });

  public handleAdditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mapPreloadActions.updateStorage),
      map((action) => action.response),
      switchMap((response) => {
        return this.mapPreloadService.handleAdditions(response.data.additions, response.data.partial)
          .pipe(
            catchError(() => EMPTY),
          );
      }),
    );
  }, {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private mapPreloadService: MapPreloadService,
  ) {}
}
