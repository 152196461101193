import { SwUpdate } from '@angular/service-worker';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/service-worker";
export class PromptUpdateService {
    constructor(document, swUpdate) {
        this.document = document;
        this.swUpdate = swUpdate;
    }
    bootstrap() {
        this.swUpdate.available.subscribe(() => {
            alert('Νέα έκδοση είναι διαθέσιμη, πατήστε "Κλείσιμο" για εγκατάσταση.');
            this.swUpdate.activateUpdate().then(() => this.document.location.reload());
        });
    }
}
PromptUpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromptUpdateService_Factory() { return new PromptUpdateService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.SwUpdate)); }, token: PromptUpdateService, providedIn: "root" });
