import { Injectable } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, concatMap, withLatestFrom, filter } from 'rxjs/operators';
import { InstallationService } from '../../services/installation.service';
import { EMPTY, of } from 'rxjs';
import * as installationActions from '../actions/installation.actions';
import * as categoriesActions from '../actions/categories.actions';
import * as entriesActions from '../actions/entries.actions';
import * as categoriesMediaActions from '../actions/categories-media.actions';
import * as entriesMediaPreloadActions from '../actions/entries-media-preload.actions';
import * as mapPreloadActions from '../actions/map-preload.actions';
import * as infoActions from '../actions/info.actions';
import * as favoritesActions from '../actions/favorites.actions';
import * as manifestActions from '../actions/manifest.actions';
import { Store, select } from '@ngrx/store';
import { AppState } from '..';
import { getInstallationId } from '../selectors/installation.selectors';
import { getQueryParams } from '../../utils/get-query-params';

@Injectable()
export class InstallationEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      filter((installationId) => !installationId),
      map(() => installationActions.fetch()),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(installationActions.fetch),
      map(() => getQueryParams().installation_id),
      filter((id) => !id),
      switchMap(() => {
        return this.installationService.fetch()
          .pipe(
            map((response) => installationActions.update(response)),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public fetchFromQuery$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(installationActions.fetch),
      map(() => getQueryParams().installation_id),
      filter<string>(Boolean),
      map((id) => installationActions.update({
        id,
      })),
    );
  });

  public requests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(installationActions.update),
      concatMap((action) => [
        categoriesActions.fetch(),
        entriesActions.fetch(),
        categoriesMediaActions.fetch(),
        entriesMediaPreloadActions.fetch(),
        mapPreloadActions.fetch(),
        infoActions.fetch(),
        favoritesActions.save(),
        manifestActions.bootstrap({
          installationId: action.id,
        }),
      ]),
    );
  });

  constructor(
    private actions$: Actions,
    private installationService: InstallationService,
    private store: Store<AppState>,
  ) {}
}
