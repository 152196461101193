import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Category } from '../../../../models/category';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-categories-widget-item',
  templateUrl: 'categories-widget-item.component.html',
})
export class CategoriesWidgetItemComponent {
  @Input()
  public category: Category;

  @Input()
  public image: string;
}
