import { Injectable } from '@angular/core';
import { createEffect, ofType, ROOT_EFFECTS_INIT, Actions } from '@ngrx/effects';
import { concatMap, withLatestFrom, filter, map, switchMap, catchError } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getInstallationId } from '../selectors/installation.selectors';
import * as categoriesMediaActions from '../actions/categories-media.actions';
import * as versionsActions from '../actions/versions.actions';
import { AppState } from '..';
import { CategoriesMediaService } from '../../services/categories-media.service';
import { Table } from '../../services/data.service';

@Injectable()
export class CategoriesMediaEffects {
  public init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap((action) => {
        return of(action)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(getInstallationId)),
              (a, installationId) => installationId,
            ),
          );
      }),
      filter<string>(Boolean),
      map(() => categoriesMediaActions.fetch()),
    );
  });

  public fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoriesMediaActions.fetch),
      switchMap(() => {
        return this.categoriesMediaService.fetch()
          .pipe(
            filter((response) => Boolean(response.data)),
            map((response) => categoriesMediaActions.updateStorage(response)),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public handleAdditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoriesMediaActions.updateStorage),
      switchMap((action) => {
        return this.categoriesMediaService.handleAdditions(action.data.additions, action.data.partial)
          .pipe(
            filter(Boolean),
            map(() => versionsActions.update({
              id: Table.CategoriesMedia,
              value: action.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  public handleDeletions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoriesMediaActions.updateStorage),
      switchMap((action) => {
        return this.categoriesMediaService.handleDeletions(action.data.deletions)
          .pipe(
            filter(Boolean),
            map(() => versionsActions.update({
              id: Table.CategoriesMedia,
              value: action.version,
            })),
            catchError(() => EMPTY),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private categoriesMediaService: CategoriesMediaService,
  ) {}
}
