import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { LinkPageReplacePipe } from './pipes/link-page-replace.pipe';
import { SharedModule } from '../../shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    NavbarComponent,
    LinkPageReplacePipe,
  ],
  imports: [
    SharedModule,
    BsDropdownModule,
  ],
  exports: [
    NavbarComponent,
  ],
})
export class NavbarModule {}
