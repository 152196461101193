import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from './store';
import * as downloadContentActions from './modules/download-content/store/actions/download-content.actions';
import * as promptInstallActions from './modules/prompt-install/store/actions/prompt-install.actions';
import { PromptInstallService } from './modules/prompt-install/services/prompt-install.service';
import { MatchMediaService } from './services/match-media.service';
import { getDownloadContentLoading } from './modules/download-content/store/selectors/download-content.selectors';
import { DownloadContentService } from './modules/download-content/services/download-content.service';
import { NetworkService } from './services/network.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  public canDownload$: Observable<boolean>;
  public promptInstall$: Observable<boolean>;
  public downloadContentLoading$: Observable<boolean>;
  public online$: Observable<boolean>;

  constructor(
    private downloadContentService: DownloadContentService,
    private promptInstallService: PromptInstallService,
    private store: Store<AppState>,
    private networkService: NetworkService,
    public matchMediaService: MatchMediaService,
  ) {}

  public ngOnInit() {
    this.canDownload$ = this.downloadContentService.canDownload$;
    this.promptInstall$ = this.promptInstallService.promptInstall$;
    this.downloadContentLoading$ = this.store.pipe(select(getDownloadContentLoading));
    this.online$ = this.networkService.online$;
  }

  public onConfirmDownloadNotifier() {
    this.store.dispatch(downloadContentActions.showModal());
  }

  public onConfirmPromptInstall() {
    this.store.dispatch(promptInstallActions.showModal());
  }
}
