import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, NavigationActionTiming, RouterStateSerializer, routerReducer } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import { CustomRouterStateSerializer, metaReducers } from '.';
import { StoreModule } from '@ngrx/store';
import { installationReducer } from './reducers/installation.reducer';

@NgModule({
  imports: [
    StoreModule.forRoot({
      router: routerReducer,
      installation: installationReducer,
    }, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class AppStoreModule {}
