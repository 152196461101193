import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PromptHelpModalComponent } from './components/prompt-help-modal/prompt-help-modal.component';
import { PromptInstallModalComponent } from './components/prompt-install-modal/prompt-install-modal.component';
import { PromptInstallStoreModule } from './store/store.module';
import { PromptInstallService } from './services/prompt-install.service';
import { PromptInstallNotifierComponent } from './components/prompt-install-notifier/prompt-install-notifier.component';

@NgModule({
  entryComponents: [
    PromptHelpModalComponent,
    PromptInstallModalComponent,
  ],
  imports: [
    PromptInstallStoreModule,
    SharedModule,
  ],
  declarations: [
    PromptHelpModalComponent,
    PromptInstallModalComponent,
    PromptInstallNotifierComponent,
  ],
  exports: [
    PromptInstallNotifierComponent,
  ],
})
export class PromptInstallModule {
  constructor(promptInstallService: PromptInstallService) {
    promptInstallService.bootstrap();
  }
}
