import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
const ɵ0 = () => import("./features/info/info.module.ngfactory").then((m) => m.InfoModuleNgFactory), ɵ1 = () => import("./features/categories/categories.module.ngfactory").then((m) => m.CategoriesModuleNgFactory), ɵ2 = () => import("./features/search/search.module.ngfactory").then((m) => m.SearchModuleNgFactory), ɵ3 = () => import("./features/entries/entries.module.ngfactory").then((m) => m.EntriesModuleNgFactory), ɵ4 = () => import("./features/map/map.module.ngfactory").then((m) => m.MapModuleNgFactory), ɵ5 = () => import("./features/favorites/favorites.module.ngfactory").then((m) => m.FavoritesModuleNgFactory), ɵ6 = () => import("./features/service/service.module.ngfactory").then((m) => m.ServiceModuleNgFactory);
const routes = [{
        path: 'info',
        loadChildren: ɵ0,
    }, {
        path: 'categories',
        loadChildren: ɵ1,
    }, {
        path: 'search',
        loadChildren: ɵ2,
    }, {
        path: 'entries',
        loadChildren: ɵ3,
    }, {
        path: 'map',
        loadChildren: ɵ4,
    }, {
        path: 'favorites',
        loadChildren: ɵ5,
    }, {
        path: 'service',
        loadChildren: ɵ6,
    }, {
        path: '',
        component: HomeComponent,
    }];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
