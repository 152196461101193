import { CategoriesMediaService } from './categories-media.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./categories-media.service";
export class CategoriesImagesService {
    constructor(categoriesMediaService) {
        this.categoriesMediaService = categoriesMediaService;
    }
    fetch(categories) {
        const images = categories.map((category) => category.image);
        return this.categoriesMediaService.fetchMany(images)
            .pipe(map((media) => {
            return media.reduce((acc, item) => {
                acc[item.id] = item.url;
                return acc;
            }, {});
        }));
    }
}
CategoriesImagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesImagesService_Factory() { return new CategoriesImagesService(i0.ɵɵinject(i1.CategoriesMediaService)); }, token: CategoriesImagesService, providedIn: "root" });
