/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./notifications.component";
import * as i6 from "../../services/notification.service";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_3)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.content; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.content; _ck(_v, 0, 0, currVal_0); }); }
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngb-toast", [["aria-atomic", "true"], ["role", "alert"]], [[8, "className", 0], [1, "aria-live", 0], [2, "toast", null], [2, "show", null]], [[null, "hide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hide" === en)) {
        var pd_0 = (_co.notificationService.remove(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NgbToast_0, i3.RenderType_NgbToast)), i1.ɵdid(1, 1622016, null, 1, i4.NgbToast, [[8, null], i4.NgbToastConfig], { delay: [0, "delay"] }, { hideOutput: "hide" }), i1.ɵqud(335544320, 1, { contentHeaderTpl: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationsComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["text", 2]], 0, 0, null, View_NotificationsComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_v.context.$implicit.delay || _co.delay); _ck(_v, 1, 0, currVal_4); var currVal_5 = _co.isTemplate(_v.context.$implicit); var currVal_6 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.classname; var currVal_1 = i1.ɵnov(_v, 1).ariaLive; var currVal_2 = true; var currVal_3 = true; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationsComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.notificationService.notifications$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "odn-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 49152, null, 0, i5.NotificationsComponent, [i6.NotificationService], null, null)], null, null); }
var NotificationsComponentNgFactory = i1.ɵccf("odn-notifications", i5.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
