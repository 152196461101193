import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DownloadContent } from '../reducers/download-content.reducer';
import { DOWNLOAD_CONTENT_STATE_KEY } from '../store.module';

export const selectDownloadContent = createFeatureSelector<DownloadContent>(DOWNLOAD_CONTENT_STATE_KEY);

export const getDownloadContentLoading = createSelector(
  selectDownloadContent,
  (downloadContent) => downloadContent.loading,
);
