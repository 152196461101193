import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { INSTALLATION_HEADER, CLIENT_VERSION_HEADER } from '../services/api.service';
import { AppState } from '../store';
import { Store, select } from '@ngrx/store';
import { getInstallationId } from '../store/selectors/installation.selectors';
import { map, switchMap, first } from 'rxjs/operators';
import { ListingServiceBase } from '../services/listing.service';
import { FavoritesService } from '../services/favorites.service';
import { ServiceInfoService } from '../features/service/services/service-info.service';

@Injectable()
export class InstallationInterceptor implements HttpInterceptor {
  private readonly urlParts = [
    ListingServiceBase.Categories,
    ListingServiceBase.Entries,
    ListingServiceBase.CategoriesMedia,
    ListingServiceBase.EntriesMedia,
    ListingServiceBase.MapTiles,
    ListingServiceBase.MapPreload,
    ListingServiceBase.Info,
    FavoritesService.base,
    ServiceInfoService.base,
  ];

  constructor(private store: Store<AppState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.shouldIntercept(req)) {
      return next.handle(req);
    }

    const headers = {};

    if (process.env.VERSION) {
      headers[CLIENT_VERSION_HEADER] = process.env.VERSION;
    }

    return this.store.pipe(
      select(getInstallationId),
      map((installation) => {
        return installation ? req.clone({
          setHeaders: {
            ...headers,
            [INSTALLATION_HEADER]: installation,
          },
        }) : req;
      }),
      first(),
      switchMap((request) => {
        return next.handle(request);
      }),
    );
  }

  private shouldIntercept(req: HttpRequest<any>) {
    return this.urlParts.some((part) => req.url.includes(part));
  }
}
