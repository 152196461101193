import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Category } from '../../models/category';
import { CategoriesImages } from '../../services/categories-images.service';
import { FALLBACK_CATEGORY_IMAGE } from './fallback-category-image';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'odn-categories-widget',
  templateUrl: 'categories-widget.component.html',
})
export class CategoriesWidgetComponent {
  public fallbackCategoryImage = FALLBACK_CATEGORY_IMAGE;

  @Input()
  public categories: Category[];

  @Input()
  public categoriesImages: CategoriesImages;

  @Input()
  public index: number;

  @Output()
  public selectCategory = new EventEmitter<Category>();

  public trackByCategoryId(index: number, category: Category) {
    return category.id;
  }
}
