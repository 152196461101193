import { CategoriesResponse } from '../../services/categories.service';
import { createAction, props } from '@ngrx/store';

export enum Actions {
  Fetch = '[Categories] Fetch',
  UpdateStorage = '[Categories] Update Storage',
}

export const fetch = createAction(Actions.Fetch);

export const updateStorage = createAction(Actions.UpdateStorage, props<CategoriesResponse>());
