/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./prompt-install-notifier.component";
var styles_PromptInstallNotifierComponent = [];
var RenderType_PromptInstallNotifierComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PromptInstallNotifierComponent, data: {} });
export { RenderType_PromptInstallNotifierComponent as RenderType_PromptInstallNotifierComponent };
export function View_PromptInstallNotifierComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "\u0395\u03B3\u03BA\u03B1\u03C4\u03AC\u03C3\u03C4\u03B1\u03C3\u03B7"], ["class", "btn btn-success odn-btn-circle odn-fixed-bottom-right shadow"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["icon", "download"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(2, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaConfig, i2.FaIconLibrary, [2, i2.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_2 = "download"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).title; var currVal_1 = i0.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PromptInstallNotifierComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "odn-prompt-install-notifier", [], null, null, null, View_PromptInstallNotifierComponent_0, RenderType_PromptInstallNotifierComponent)), i0.ɵdid(1, 49152, null, 0, i4.PromptInstallNotifierComponent, [], null, null)], null, null); }
var PromptInstallNotifierComponentNgFactory = i0.ɵccf("odn-prompt-install-notifier", i4.PromptInstallNotifierComponent, View_PromptInstallNotifierComponent_Host_0, {}, { confirm: "confirm" }, []);
export { PromptInstallNotifierComponentNgFactory as PromptInstallNotifierComponentNgFactory };
