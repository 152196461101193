import { createAction, props } from '@ngrx/store';
import { MediaResponse } from '../../../../services/media.service';

export interface FetchSuccessProps {
  response: {
    entries: MediaResponse;
    map: MediaResponse;
  };
}

export interface LoadingProps {
  loading: boolean;
}

export enum Actions {
  Init = '[Download Content] Init',
  ShowModal = '[Download Content] Show Modal',
  Fetch = '[Download Content] Fetch',
  FetchSuccess = '[Download Content] Fetch Success',
  FetchFailed = '[Download Content] Fetch Failed',
  Remove = '[Download Content] Remove',
  RemoveSuccess = '[Download Content] Remove Success',
  Loading = '[Download Content] Loading',
}

export const init = createAction(Actions.Init);

export const showModal = createAction(Actions.ShowModal);

export const fetch = createAction(Actions.Fetch);

export const fetchSuccess = createAction(Actions.FetchSuccess, props<FetchSuccessProps>());

export const fetchFailed = createAction(Actions.FetchFailed);

export const remove = createAction(Actions.Remove);

export const removeSuccess = createAction(Actions.RemoveSuccess, props<FetchSuccessProps>());

export const loading = createAction(Actions.Loading, props<LoadingProps>());
